import React from 'react';
import './PlayoffMatchup.scss';

function Team({ team, seed, player, score }) {
    return <div className="team">
        <div className="seed">{seed || '-'}</div>
        <div className="detail">
            <div className="name">{team || '-' }</div>
            <div className="player">{player || '-' }</div>
        </div>
        <div className="score">{score || '-'}</div>
    </div>
}

function Champion({ champ }) {
    return (
        <div className={`playoff-matchup superbowl`}>
            { champ ? <Team seed={champ.seed} team={champ.teamId} player={champ.playerId} score="-"/> : <Team /> }
        </div>
    );
}

function PlayoffMatchup({ home, away, conference, isChampion }) {
    // if this is the champion, render him a little differently.
    if (isChampion) {
        return <Champion champ={home}/>
    }

    return (
        <div className={`playoff-matchup ${conference}`}>
            { away ? <Team seed={away.seed} team={away.teamId} player={away.playerId} score={away.score} /> : <Team /> }
            { home ? <Team seed={home.seed} team={home.teamId} player={home.playerId} score={home.score} /> : <Team /> }
        </div>
    );
}

export default PlayoffMatchup;
