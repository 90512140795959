import React from 'react';
import Playoffs from '../components/Playoffs';
import PlayerSnapshot from '../components/PlayerSnapshot';
import Payouts from '../components/Payouts';
import WallOfChampions from '../components/WallOfChampions';
import WeekSnapshot from '../components/WeekSnapshot';
import WeekPreview from '../components/WeekPreview';

class Home extends React.Component {
    constructor(props) {
        super(props);
    
        this.state = { 
            loading: true,
            site: {}
        };
    }

    componentDidMount() {
        fetch("/api/playoffs/details/", { cache: "no-cache", credentials: 'same-origin', headers: { 'Accept': 'application/json' }})
        .then(res => res.json())
        .then(
            (response) => {
                this.setState({
                    loading: false,
                    site: response.site
                });
        },
        (error) => {
            this.setState({
                loading: false,
            });
        }
        )
    }


    render() {
        const { loading, site } = this.state;

        if (loading) {
            return <div />
        } else if (site.playoffsActive) {
            return (
                <div className="container mt-4">
                    <div className="row">
                        <div className="col-12 col-lg-8">
                            <PlayerSnapshot></PlayerSnapshot>
                            <Playoffs></Playoffs>
                        </div>
                        <div className="col-12 col-lg-4">
                            <Payouts></Payouts>
                            <WallOfChampions></WallOfChampions>
                        </div>
                    </div>
                </div>
            );
        }
        return (
            <div className="container mt-4">
                <div className="row">
                    <div className="col-12 col-lg-8">
                        <PlayerSnapshot></PlayerSnapshot>
                        <WeekPreview></WeekPreview>
                    </div>
                    <div className="col-12 col-lg-4">
                        <WeekSnapshot></WeekSnapshot>
                        <Payouts></Payouts>
                        <WallOfChampions></WallOfChampions>
                    </div>
                </div>
            </div>
        );
    }
}
  

export default Home;