import React from 'react';
import LockPlayoffUsersModal from '../components/LockPlayoffUsersModal';
import ResetSeasonModal from '../components/ResetSeasonModal';

class LeagueSettings extends React.Component {
    render() {
        return (
            <div className="container mt-4">
                <ResetSeasonModal />
                <LockPlayoffUsersModal />
                <div className="row">
                    <div className="col">
                        <h4 className="title">League Settings</h4>
                        <hr />
                    </div>
                </div>
                <div className="row mb-3">
                    <div className="col">
                        <div className="panel mb-3">
                            <div className="row">
                                <div className="col">
                                    <h6 className="title">Reset For New Season</h6>
                                    <hr />
                                </div>
                            </div>
                            <div className="row">
                                <div class="mx-3 mb-2">This option will make the appropriate updates to set the league up for a new season.  In order for this option to be available, you must explicitly set the "allow reset" option on the backend.
                                Once this option is set, you can proceed with this option to setup for a new season.  Since this is only intended to be run once per season, the "allow reset" option will be reset once this script runs
                                successfully.
                                
                                One thing to verify before running this option is to ensure that all results and payouts are current from the
                                previous season as this script will perform actions that relies on that data being up to date.  This script in essence will perform the following steps:
                                <ul class="mt-2">
                                    <li>Creates new entry track user's cash totals for new season.</li>
                                    <li>Creates new entry to track user's point totals for new season.</li>
                                    <li>Creates new entry to track user's stats for new season.</li>
                                    <li>Creates new entry to track user's pick history for new season.</li>
                                    <li>Clears all user's paid status for season.</li>
                                    <li>Resets all team records and stats for new season.</li>
                                    <li>Updates champions from last season (point, cash, and overall)</li>
                                    <li>Auto-advances to next season and resets environment variables for start of new season.</li>
                                </ul>

                                </div>
                                <div className="col text-center">
                                    <button name="btn-delete" type="button" className="btn btn-danger" data-toggle="modal" data-target="#reset-season-modal">Reset</button>
                                </div>
                            </div>
                        </div>
                        <div className="panel mb-3">
                            <div className="row">
                                <div className="col">
                                    <h6 className="title">Lock Playoff Users</h6>
                                    <hr />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col text-center">
                                    <button name="btn-delete" type="button" className="btn btn-danger" data-toggle="modal" data-target="#lock-playoff-users-modal">Lock</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
  

export default LeagueSettings;