import React from 'react';
import './UserPicks.scss';

function PickSet(props) {
    let element;

    switch (props.status) {
        case 'valid':
            element = props.pickSet.map((pick, index) => (
                <td key={`pick${index}`} className={`d-none d-lg-table-cell text-center ${pick.result > 0 ? 'winner' : ''} ${pick.result < 0 ? 'loser' : ''}`} data-js="pick" data-pick={pick.show && pick.teamId} data-game-id={pick.gameId}>
                    {
                        pick.show ?
                        <span>
                            <img className="d-none d-xl-inline-block" src={`./img/helmets/32/${pick.teamId}.png`} alt={pick.teamId} />
                            <div>{pick.teamId}</div>
                        </span>
                    :
                        <span>
                            <img className="d-none d-xl-inline-block" height="32" width="32" src="./img/icons/silhouette.svg" alt="" />
                            <div>-</div>
                        </span>
                    }
                </td>
            ));
            break;
        case 'invalid':
            element = <td className="d-none d-lg-table-cell text-center text-bold" colSpan={props.numGames}>Picks Incomplete</td>;
            break;

        case 'missing':
            element = <td className="d-none d-lg-table-cell text-center text-bold" colSpan={props.numGames}>No Picks Received</td>;
            break;
        default: break;
    }

    return element;
}

function UserTiebreaker(props) {
    let element = <td className="text-center" data-js="tiebreaker">-</td>;
    if (props.user.status === 'valid') {
        if (props.resultsFinal) {
            element = <td className="text-center" data-js="tiebreaker">{(props.user.diffFromTie > 0) && '+'}{props.user.diffFromTie}</td>    
        } else if (props.loggedInUser === props.user.id || props.finalDeadlinePassed) {
            element = <td className="text-center" data-js="tiebreaker">{props.user.tiebreaker}</td>;
        }
    }
    return element;
}

function UserList(props) {
    const list = props.users.filter((user) => {
        return ((user.id === props.loggedInUser) || // this user is me.
                !props.filter || // no filter active.
                (props.filter && props.filter.indexOf(user.id) >= 0)); // user exists in the filter list.
    }).map((user, index) => (
        <tr key={`user${index}`} className={(props.loggedInUser === user.id) ? 'active-user' : undefined} data-filter="favorite" data-js={user.picks ? 'user-picks' : undefined} data-user-id={user.id} data-invalid={user.status === 'valid' ? 'false' : 'true'}>
            <th scope="row" data-js="place">
                {user.picks ? user.place : '-'}
            </th>
            <td>
                { user.inTheMoney &&
                    <svg width="1rem" height="1rem" viewBox="0 0 16 16" className="bi bi-star-fill" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                      <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.283.95l-3.523 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z"/>
                    </svg>
                }
                &nbsp;{user.id}
            </td>
            <PickSet
                pickSet={user.picks}
                numGames={props.numGames}
                status={user.status}
            ></PickSet>
            <td className="text-center font-weight-bold" data-js="points-total">{user.totalPoints}</td>
            <td className="text-center" data-js="points-possible">{user.possiblePoints}</td>
            <UserTiebreaker
                user={user}
                resultsFinal={props.resultsFinal}
                loggedInUser={props.loggedInUser}
                finalDeadlinePassed={props.finalDeadlinePassed}
            ></UserTiebreaker>
        </tr>
    ));

    return list;
}

function ConfidenceHeader(props) {
    const confidences = [];
    for (let i = 0; i < props.numGames; ++i) {
        confidences.push(<th key={`confidence${i}`} className="d-none d-lg-table-cell text-center">{i+1}</th>);
    }

    return confidences;
}

class UserPicks extends React.Component {
    render() {
        return (
            <div className="m-user-picks">
                <table className="table table-sm">
                    <thead className="thead-inverse">
                        <tr>
                            <th>#</th>
                            <th>Player</th>
                            <ConfidenceHeader numGames={this.props.numGames}></ConfidenceHeader>
                            <th className="text-center">TOT</th>
                            <th className="text-center">POS</th>
                            <th className="text-center">TIE</th>
                        </tr>
                    </thead>
                    <tbody data-js="user-picks-container">
                        { this.props.userPicks &&
                            <UserList 
                                users={this.props.userPicks}
                                loggedInUser={this.props.loggedInUser}
                                resultsFinal={this.props.resultsFinal}
                                finalDeadlinePassed={this.props.finalDeadlinePassed}
                                numGames={this.props.numGames}
                                filter={this.props.filter}
                            ></UserList>
                        }
                    </tbody>
                </table>
            </div>
        );
    }
}

export default UserPicks;