
import React from 'react';
import WeekPaginator from './WeekPaginator';
import './GameDay.scss';

function GameCapsule(props) {
    const game = props.game;
    return (
        <div className="game-capsule panel px-0 py-2 mb-2">
            <div className="row header">
                <div className="col">
                    <h6 className="text-center game-date text-bold">{game.result ? 'FINAL' : game.info.kickoff} at {game.info.venue}</h6>
                    <hr className="mb-2" />
                </div>
            </div>

            <div className="row body no-gutters">
                <div className="col-5">
                    <div className="game-logo text-center"><img src={`img/helmets/128/${game.away.id}.png`} alt={game.away.name} /></div>
                    <div className="game-team text-center text-uppercase">{game.away.name}</div>
                    { game.result ?
                        <div className="game-result text-center">{game.result[game.away.id]}</div>
                    :
                        <div className="game-record text-center">0-0-0</div>
                    }
                </div>
                <div className="game-divider col-2 text-center text-bold">@</div>
                <div className="col-5">
                    <div className="game-logo text-center"><img src={`img/helmets/128/${game.home.id}.png`} alt={game.home.name} /></div>
                    <div className="game-team text-center text-uppercase">{game.home.name}</div>
                    { game.result ?
                        <div className="game-result text-center">{game.result[game.home.id]}</div>
                    :
                        <div className="game-record text-center">0-0-0</div>
                    }
                </div>
            </div>
        </div>
    );
}

function GameCapsules(props) {
    if (props.schedule) {
        const games = props.schedule.map((game, index) => (
            <div key={`game${index}`} className="col-12 col-md-6 col-lg-4">
                <GameCapsule game={game}></GameCapsule>
            </div>
        ));

        return games; 
    }
    return (
        <div className="col-12 col-md-6 col-lg-4">
        </div>
    );
}


class GameDay extends React.Component {
    constructor(props) {
        super(props);

        this.handleClick = this.handleClick.bind(this);

        this.state = {
            loading: true,
            schedule: null,
            site: {},
        };

    }

    getSchedule(week) {
        let url = '/api/nfl/schedule/';
        if (week) {
            url += `?week=${week}`
        }

        // only make this call if we have a valid user.
        fetch(url,
        {
            cache: "no-cache",
            headers: {
                'Accept': 'application/json'
            }
        })
        .then(res => res.json())
        .then(
            (response) => {
                this.setState({
                    loading: false,
                    games: response.schedule,
                    site: response.site,
                });
            },
            // Note: it's important to handle errors here
            // instead of a catch() block so that we don't swallow
            // exceptions from actual bugs in components.
            (error) => {
                this.setState({
                    loading: false
                });
            }
        );
    }

    handleClick(e) {
        this.getSchedule(e.target.getAttribute('data-week'));

        e.preventDefault();
    }

    componentDidMount() {
        this.getSchedule();
    }

    render() {
        return (
            <div className="m-gameday">
                <div className="row">
                    <div className="col">
                        <h4 className="title">NFL Week {this.state.site.requestedWeek}</h4>
                    </div>
                </div>
                <div className="row mb-3">
                    <div className="col">
                    <WeekPaginator
                        weekCount={this.state.site.weekCount}
                        requestedWeek={this.state.site.requestedWeek}
                        handleClick={this.handleClick}>
                        </WeekPaginator>
                    </div>
                </div>
                <div className="row game-container">
                    <div className="col">
                        <div className="row">
                            <GameCapsules schedule={this.state.games}></GameCapsules>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default GameDay;