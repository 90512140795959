import React from 'react';

class SignOut extends React.Component {
    constructor(props) {
        super(props);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleSubmit(e) {
        let formData = new FormData();

        formData.append('userid', this.props.user.name);

        // get the current user, if valid.
        fetch("/api/authentication/signout/",
            {
                method: "POST",
                cache: "no-cache",
                credentials: 'same-origin', // This is required to send the cookies in the request.
                body: formData,
                headers: {
                    'Accept': 'application/json'
                }
            })
        .then(res => res.json())
        .then(
            (response) => {
                // if logging out, go back to home page.
                window.location.href = '/';
            },
            // Note: it's important to handle errors here
            // instead of a catch() block so that we don't swallow
            // exceptions from actual bugs in components.
            (error) => {
                window.location.href = '/';
            }
        )

        e.preventDefault();
    }

    render() {
        return (
            <form onSubmit={this.handleSubmit}>
                <input type="hidden" name="userid" value={this.props.user.name}></input>
                <button type="submit" className="btn btn-default btn-sm">Sign Out</button>
            </form>
        );
    }
}

export default SignOut;