import React from 'react';
import WeekPaginator from '../components/WeekPaginator';
import ScenarioGenerator from '../components/ScenarioGenerator';
import UserPicks from '../components/UserPicks';

class Results extends React.Component {
    constructor(props) {
        super(props);

        this.handleToggleFavorites = this.handleToggleFavorites.bind(this);
        this.handleWeekSelected = this.handleWeekSelected.bind(this);
        this.handleGenerateScenario = this.handleGenerateScenario.bind(this);
        this.handleSetTiebreaker = this.handleSetTiebreaker.bind(this);

        // the user here will be shared by other pages/components throughout the app
        this.state = {
            loading: true,
            showFavorites: false,
            numGames: 0,
            confidences: null,
            finalDeadlinePassed: false,
            resultsFinal: false,
            tiebreaker: null,
            scenarioTiebreaker: '',
            userPicks: null,
            schedule: null,
            site: {},
            user: {}
        };
    }

    handleSetTiebreaker(e) {
        this.setState({
            scenarioTiebreaker: e.currentTarget.value
        });
    }

    getResults(week) {
        let url = '/api/league/results/';
        if (week) {
            url += `?week=${week}`
        }

        // only make this call if we have a valid user.
        fetch(url,
        {
            cache: "no-cache",
            headers: {
                'Accept': 'application/json'
            }
        })
        .then(res => res.json())
        .then(
            (response) => {
                this.setState({
                    loading: false,
                    numGames: response.site.gameCount,
                    schedule: response.schedule,
                    finalDeadlinePassed: response.site.finalDeadlinePassed,
                    tiebreaker: response.site.tiebreaker,
                    scenarioTiebreaker: '',
                    userPicks: response.userPicks,
                    user: response.user,
                    site: response.site
                });
            },
            // Note: it's important to handle errors here
            // instead of a catch() block so that we don't swallow
            // exceptions from actual bugs in components.
            (error) => {
                this.setState({
                    loading: false
                });
            }
        );
    }

    handleToggleFavorites(e) {
        this.setState({
            showFavorites: !this.state.showFavorites
        })
    }

    handleWeekSelected(e) {
        this.getResults(e.target.getAttribute('data-week'));

        e.preventDefault();
    }

    handleGenerateScenario(e) {
        const userPicks = [...this.state.userPicks];
        const scenarioPicks = [...document.querySelectorAll('[data-js="scenario-game"]')].map((game) => {
            const winner = game.querySelector('button.winner');

            return  {
                id: game.getAttribute('data-game-id'),
                pick: winner ? winner.getAttribute('data-id') : null
            };
        });


        // doesn't matter if they didn't make any selections or we don't have any picks to compare against.
        if (scenarioPicks.length && userPicks.length) {
            let lowPoints = 999;
            // loop through each pick and apply the winners
            userPicks.forEach((user) => {
                // reset their points, we're gonna run through that now.
                user.totalPoints = 0;
                user.possiblePoints = 0;
                user.diffFromTie = 0;

                // determine their diff from tie based on the current tiebreaker value set.
                if (this.state.scenarioTiebreaker) {
                    if (user.tiebreaker) {
                        if (user.tiebreaker > this.state.scenarioTiebreaker) {
                            user.diffFromTie = `+${user.tiebreaker - this.state.scenarioTiebreaker}`;
                        } else {
                            user.diffFromTie = `-${this.state.scenarioTiebreaker - user.tiebreaker}`;
                        }
                    } else {
                        user.diffFromTie = `-${this.state.scenarioTiebreaker}`;
                    }
                }

                // only count them if their picks are valid.
                if (user.status === 'valid') {
                    user.picks.forEach((pick, index) => {
                        const scenarioPick = scenarioPicks.find(scenarioPick => scenarioPick.id === pick.gameId);
                        if (scenarioPick) {
                            // if no pick in scenario AND the user has a pick in this game, then it's possible, but not counted in total.
                            if (!scenarioPick.pick && pick.teamId) {
                                pick.result = 0;
                                user.possiblePoints += (index + 1);
                            } else if (scenarioPick.pick === pick.teamId) {
                                // winner scenario, both possible and add to total.
                                pick.result = 1;
                                user.totalPoints += (index + 1)
                                user.possiblePoints += (index + 1);
                            } else {
                                // loser scenario, neither possible or add to total.
                                pick.result = -1;
                            }
                        }
                    })
                    if (user.totalPoints < lowPoints) {
                        lowPoints = user.totalPoints;
                    }
                }
            })

            // second loop through, set low points for invalid picks.
            userPicks.forEach((user) => {
                if (user.status !== 'valid') {
                    user.totalPoints = user.possiblePoints = (lowPoints -  1);
                }
            });

            // next, sort the results by overall total
            userPicks.sort((a, b) => {
                const val = b.totalPoints - a.totalPoints;
                const scenarioTiebreaker = this.state.scenarioTiebreaker;
            
                
                // break the tie if these two users are tied.
                if (val === 0 && scenarioTiebreaker) {
                    var aDiff = Math.abs((a.tiebreaker || 0) - scenarioTiebreaker);
                    var bDiff = Math.abs((b.tiebreaker || 0) - scenarioTiebreaker);

                    // check for tie of tiebreaker.
                    if (bDiff === aDiff) {
                        // if they're tied sort alphabetically.
                        if (b.id > a.id) {
                            return -1;
                        } else {
                            return 1;
                        }
                    } else if (bDiff < aDiff) {
                        // b is closer than a, put b before a
                        return 1;
                    } else {
                        // a is closer than b, put a before b
                        return -1;
                    }
                }
                return val;
            });

            // last pass, assign the place for each user now that everything has been applied..
            let place = 1;
            let lastTiebreaker = userPicks[0].tiebreaker;
            let lastTotal = userPicks[0].totalPoints;
            
            userPicks.forEach((user, index) => {
                if (user.totalPoints !== lastTotal || user.tiebreaker !== lastTiebreaker) {
                    lastTiebreaker = user.tiebreaker;
                    lastTotal = user.totalPoints;

                    place = index + 1;
                }
                user.place = place;
            });

            this.setState({
                userPicks
            });
        }
        e.preventDefault();
    }

    componentDidMount() {
        this.getResults();
    }

    render() {
        return (
            <div className="container mt-4">
                <div className="row">
                    <div className="col">
                        <h4 className="title">Week {this.state.site.requestedWeek} Results</h4>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                        <WeekPaginator
                            weekCount={this.state.site.weekCount}
                            requestedWeek={this.state.site.requestedWeek}
                            handleClick={this.handleWeekSelected}>
                        </WeekPaginator>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                        <ul className="results-options px-0 mb-3">
                            <li className="mr-3 mb-3">
                                <button className="btn btn-default btn-sm" data-toggle="collapse" data-target="#scenario-generator" aria-expanded="false" aria-controls="scenario-generator">
                                    Scenario Generator
                                </button>
                            </li>
                            <li className="mb-3">
                                <button type="button" className={`btn btn-default btn-sm ${(this.state.user && this.state.user.favorites) ? '' : 'disabled'}`} onClick={this.handleToggleFavorites}>
                                    Show Favorites
                                </button>
                            </li>
                        </ul>
                    </div>
                </div>
                <div className="row">
                    {this.state.schedule ? 
                        <div className="col-12">
                            <ScenarioGenerator
                                schedule={this.state.schedule}
                                handleGenerateScenario={this.handleGenerateScenario}
                                tiebreaker={this.state.scenarioTiebreaker}
                                handleSetTiebreaker={this.handleSetTiebreaker}
                                finalDeadlinePassed={this.state.finalDeadlinePassed}>
                            </ScenarioGenerator>
                            <UserPicks
                                numGames={this.state.numGames}
                                finalDeadlinePassed={this.state.finalDeadlinePassed}
                                resultsFinal={this.state.resultsFinal}
                                tiebreaker={this.state.tiebreaker}
                                userPicks={this.state.userPicks}
                                loggedInUser={this.state.user ? this.state.user.name : null}
                                filter={(this.state.showFavorites && this.state.user && this.state.user.favorites) ? this.state.user.favorites : null}
                            ></UserPicks>
                        </div>
                        :
                        <div className="col-12 text-center">
                            The schedule for this week has not yet been posted.
                        </div>
                    }
                </div>
            </div>
        );
    }
}
  

export default Results;

