import React from 'react';
import GameDay from '../components/GameDay';

class NflSchedule extends React.Component {
    render() {
        return (
            <div className="container mt-4">
                <div className="row">
                    <div className="col">
                        <GameDay></GameDay>
                    </div>
                </div>
            </div>
        );
    }
}
  

export default NflSchedule;