
import React from 'react';
import { TiebreakerContext } from './TiebreakerContext';
import './Tiebreaker.scss'

function Tiebreaker(props) {
    return (
        <TiebreakerContext.Consumer>
            {({value, setTiebreaker}) => (
                <label className="tiebreaker text-center">
                    <span>Tiebreaker</span>
                    <input className="form-control" type="text" data-js="tiebreaker" name="tiebreaker" value={value} onChange={(e)=> { setTiebreaker(e.currentTarget.value)}} size="2" maxLength="2" pattern="[0-9]+" required />
                </label>
            )}
        </TiebreakerContext.Consumer>
    )
}

export default Tiebreaker;