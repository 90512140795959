import React from 'react';
import './Matchup.scss';
import Tiebreaker from './Tiebreaker';

function Handle(props) {
    return (
        <div className="game-info bg-dark px-1 py-2">
            {!props.isPlayoffs &&
            <div className="info px-3 d-none d-md-inline-block">
                <span className="text-bold">Deadline:&nbsp;</span>{props.info.deadline}<br />
                <span className="text-bold">Kickoff:&nbsp;</span>{props.info.kickoff}<br />
                <span className="text-bold">Venue:&nbsp;</span>{props.info.venue}<br />
            </div>
            }
            <div className="d-inline-block d-md-none">
                <button disabled={props.info.deadlinePassed} onClick={props.handleMoveUp} data-js="move-up" type="button" className="move-up btn btn-default btn-sm">
                <svg width="1em" height="1em" viewBox="0 0 16 16" className="bi bi-chevron-up" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                    <path fillRule="evenodd" d="M7.646 4.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1-.708.708L8 5.707l-5.646 5.647a.5.5 0 0 1-.708-.708l6-6z"/>
                </svg>
                </button>
                &nbsp;
                &nbsp;
                <button disabled={props.info.deadlinePassed} onClick={props.handleMoveDown} data-js="move-down" type="button" className="move-down btn btn-default btn-sm">
                    <svg width="1em" height="1em" viewBox="0 0 16 16" className="bi bi-chevron-down" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                        <path fillRule="evenodd" d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"/>
                    </svg>
                </button>
            </div>
            {props.info.isTiebreaker &&
                <span className="d-none d-md-inline">
                    <Tiebreaker tiebreaker={props.tiebreaker}></Tiebreaker>
                </span>
            }
            <div className="d-md-none pt-1 kickoff-short font-weight-bold text-center">{props.info.kickoffShort}</div>
            {!props.info.deadlinePassed &&
                <div className="handle d-none d-md-block mr-3">
                    <svg width="50" height="50" viewBox="0 0 16 16" className="bi bi-three-dots-vertical" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                        <path fillRule="evenodd" d="M9.5 13a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z"/>
                    </svg>
                </div>
            }
        </div>
    );
}

function Team(props) {
    return (
        <div className={`team py-2 ${props.pick === props.team.id ? 'selected' : ''}`} data-id={props.team.id} data-js="team">
            <img className="pointer-none team-logo d-none d-lg-inline pl-3" src={`img/helmets/48/${props.team.id}.png`} alt={props.team.id} />
            <div className="pointer-none pl-3 d-none d-md-inline-block">
                    <span className="team-name">{props.team.name}</span>
                    { props.isPlayoffs ?
                        <span>
                            <br />
                            <span className="text-bold">Conference: {props.team.conference}</span><br />
                            <span className="text-bold">Conference Rank: {props.team.playoffSeed}</span><br />
                        </span>
                        :
                        <span>
                            <br />
                            <span className="text-bold">Overall: {props.team.wins}-{props.team.losses}-{props.team.ties}</span><br />
                            <span className="text-bold">{props.team.label}: {props.team.altWins}-{props.team.altLosses}-{props.team.altTies}</span><br />
                        </span>
                    }
            </div>
            <div className="pointer-none d-md-none text-center">
                <span className="team-name">{props.team.id}</span>
            </div>
        </div>
        
    );
}

class Matchup extends React.Component {
    constructor(props) {
        super(props);

        this.handleSelect = this.handleSelect.bind(this);
        this.handleDragStart = this.handleDragStart.bind(this);
        this.handleDragEnd = this.handleDragEnd.bind(this);

        this.state = {
            pick: props.game.info.pick,
            isBeingDragged: false,
        }
    }

    handleSelect(e) {
        // no selections on playoffs.
        if (!this.props.isPlayoffs) {
            if (e.target.getAttribute('data-js') === 'team' &&
                (e.currentTarget.getAttribute('data-locked') !== 'true')) {
                this.setState({
                    pick: e.target.getAttribute('data-id')
                });
            }
        }
        
        e.stopPropagation();
        e.preventDefault();
    }

    handleDragStart(e) {
        window.requestAnimationFrame(() => {
            this.setState({
                isBeingDragged: true
            });
        });
        this.props.handleDragStart(e);
    }

    handleDragEnd(e) {
        this.setState({
            isBeingDragged: false
        });

        this.props.handleDragEnd(e);
    }

    render() {
        const { game, isPlayoffs } = this.props;
        return (
            <div 
                className={`matchup ${this.state.isBeingDragged ? 'dragged' : ''} ${this.props.showPlayoffCutoff && 'playoffCutoffMarker'}`}
                data-js="matchup"
                draggable={!game.info.deadlinePassed}
                data-id={game.info.id}
                data-pick={this.state.pick}
                data-locked={game.info.deadlinePassed}
                onClick={this.handleSelect}
                onDragOver={this.props.handleDragOver}
                onDragStart={this.handleDragStart}
                onDragEnd={this.handleDragEnd}
                onDragEnter={this.props.handleDragEnter}
                onDrop={this.props.handleDrop}
            >
                { !isPlayoffs &&
                    <Team pick={this.state.pick} team={game.awayTeam}></Team>
                }
                <Team isPlayoffs={isPlayoffs} pick={this.state.pick} team={game.homeTeam}></Team>
                <Handle isPlayoffs={isPlayoffs} info={game.info} tiebreaker={this.props.tiebreaker} handleMoveUp={this.props.handleMoveUp} handleMoveDown={this.props.handleMoveDown}></Handle>
            </div>
        );
    }
}

export default Matchup;