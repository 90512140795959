import React, { useState } from 'react';

function AddNewPlayerModal(props) {
    const [ userId, setUserId ] = useState('');
    const [ firstName, setFirstName ] = useState('');
    const [ lastName, setLastName ] = useState('');
    const [ email, setEmail ] = useState('');
    const [ statusMessage, setStatusMessage ] = useState('');

    const handleSubmit = async (e) => {
        e.preventDefault();

        const reqBody = {
            userId,
            firstName,
            lastName,
            email
        };

        // only make this call if we have a valid user.
        const res = await fetch("/api/admin/manage-players/",
        {
            method: "POST",
            cache: "no-cache",
            credentials: 'same-origin', // This is required to send the cookies in the request.
            body: JSON.stringify(reqBody),
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        });

        const data = await res.json();
        setStatusMessage(data.message);
        props.refreshPlayers();
    };

    return (
        <div className="modal fade" id="add-player-modal" tabIndex="-1" role="dialog" aria-labelledby="add-player-label">
            <div className="modal-dialog" role="document">
                <div className="modal-content">
                <form name="log-in" onSubmit={handleSubmit}>
                <div className="modal-header">
                    <h4 className="modal-title">Add Player</h4>
                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div className="modal-body">
                    <div className="form-group error" data-js="error">{statusMessage}</div>
                    <div className="form-group">
                        <label htmlFor="userid">Username</label>
                        <input type="text" autoComplete="username" name="userId" id="userid" className="form-control" value={userId} onChange={e => setUserId(e.target.value)} />
                    </div>
                    <div className="form-group">
                        <label htmlFor="firstName">First Name</label>
                        <input type="text" name="firstName" id="firstName" className="form-control" value={firstName} onChange={e => setFirstName(e.target.value)} />
                    </div>
                    <div className="form-group">
                        <label htmlFor="lastName">Last Name</label>
                        <input type="text" name="lastName" id="lastName" className="form-control" value={lastName} onChange={e => setLastName(e.target.value)} />
                    </div>
                    <div className="form-group">
                        <label htmlFor="email">Email</label>
                        <input type="text" name="email" id="email" className="form-control" value={email} onChange={e => setEmail(e.target.value)} />
                    </div>
                </div>
                <div className="modal-footer">
                    <button type="button" className="btn btn-default" data-dismiss="modal">Cancel</button>
                    <button type="submit" className="btn btn-primary">Add Player</button>
                </div>
            </form>
                </div>
            </div>
        </div>
    )
}

export default AddNewPlayerModal;