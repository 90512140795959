import React, { useState, useEffect } from 'react';
import './PlayoffSeeds.scss';

function PlayoffSeeds() {
    const [ state, setState ] = useState({
        initialized: false,
        users: [],
    });

    const { initialized, users } = state;

    useEffect(() => {
        if (!initialized) {
            // only make this call if we have a valid user.
            fetch("/api/playoffs/users/", { cache: "no-cache", headers: { 'Accept': 'application/json' }})
            .then(res => res.json())
            .then(
                (response) => {
                    setState({
                        initialized: true,
                        users: response.users,
                    });
                },
                (error) => {
                }
            );
        }
    }, [ initialized ]);

    return (
        <div className="m-playoff-seeds">
            <table className="table table-sm" data-js="filter" data-id="seeds">
                <thead className="thead-inverse">
                    <tr>
                        <th>Seed</th>
                        <th>Player</th>
                        <th>Pick</th>
                    </tr>
                </thead>
                <tbody>
                    { users.map((user, index) => (
                        <tr key={`playoffseed-${index}`}>
                            <td>{user.seed}</td>
                            <td>{user.name}</td>
                            <td>{user.pick || '-'}</td>
                        </tr>
                        ))
                    }
                </tbody>
            </table>
        </div>
    );
}

export default PlayoffSeeds;