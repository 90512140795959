import React, { useState } from 'react';
import './ScenarioGenerator.scss';


function Game(props) {
    const [ selection, setSelection ] = useState(props.winner);
    return (

        <div className="btn-group btn-group-sm text-center mb-2 game" role="group" data-game-id={props.id} data-js="scenario-game">
            <button type="button" className={`team btn btn-default ${(selection === props.homeTeam) ? 'winner' : ''}`} onClick={(e) => setSelection(props.homeTeam)} data-id={props.homeTeam}>
                <img className="d-block" src={`./img/helmets/32/${props.homeTeam}.png`} alt={props.homeTeam} />
                {props.homeTeam}
            </button>
            <button type="button" className={`team btn btn-default ${(selection === props.awayTeam) ? 'winner' : ''}`} onClick={(e) => setSelection(props.awayTeam)} data-id={props.awayTeam}>
                <img className="d-block" src={`./img/helmets/32/${props.awayTeam}.png`} alt={props.awayTeam} />
                {props.awayTeam}
            </button>
        </div>
    );
}

class ScenarioGenerator extends React.Component {
    render() {
        return (
            <div id="scenario-generator" className="m-scenario-generator collapse panel mb-3">
                { this.props.finalDeadlinePassed ?
                    <form onSubmit={this.props.handleGenerateScenario}>
                        <div className="row">
                            { this.props.schedule.map((game, index) => (
                                <div key={`scenario${index}`} className="col-4 col-md-3 col-lg-2">
                                    <Game {...game}></Game>
                                </div>
                            ))}
                        </div>
                        <div className="row">
                            <div className="col text-center mt-2">
                                <button type="submit" className="btn btn-primary">Generate Scenario</button>
                                <div className="pl-3 d-inline-block">
                                    <label className="mb-1 font-weight-bold" htmlFor="tiebreaker">Tie</label>
                                    <input className="form-control" type="text" name="tiebreaker" value={this.props.tiebreaker} onChange={this.props.handleSetTiebreaker} size="2" maxLength="2" pattern="[0-9]+" />
                                </div>
                            </div>
                        </div>
                    </form>
                    :
                    <div>
                        The scenario generator is only available after the final deadline has passed for the week.
                    </div>
                }
            </div>
        );
    }
}

export default ScenarioGenerator;