import React from 'react';

function PlayoffSlot({ conference, slotId, teams, selectedId, handleInputChange }) {
    return (
        <div className="row">
            <div className="col-12">
                <label>Playoff Seed {slotId}</label>
                <select name={`${conference}${slotId}`} value={selectedId} onChange={handleInputChange} className="form-control" >
                    <option key="team0" value="">-</option>
                    { teams.map((team, index) => (
                        <option key={`team${index + 1}`} value={team.id}>{team.name}</option>
                    ))}
                </select>
            </div>
        </div>
    );
}

export default PlayoffSlot