import React from 'react';
import './Leaderboards.scss';

function WeekColumns(props) {
    const columns = [];
    for (let i = 1; i <= props.weekCount; ++i) {
        columns.push(<th key={`col${i}`} className="d-none d-lg-table-cell text-center">{i}</th>);
    }

    return columns;
}

function PointLeaders(props) {
    if (!props.leaders) {
        return <div></div>;
    }

    const userId = props.user ? props.user.id : null;
    return (
        <div>
            <table className="table table-striped table-sm">
                <thead className="thead-inverse">
                    <tr>
                        <th>#</th>
                        <th>Player</th>
                        <WeekColumns weekCount={props.weekCount}></WeekColumns>
                        <th className="text-center">TOT</th>
                        <th className="text-center">PBL</th>
                        <th className="text-center">PNFP</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        props.leaders.map((player, index) => (
                            <tr key={`pointLeader${index}`} className={`${userId === player.id ? 'active-user' : ''} ${(index + 1) === props.playoffLineIndex ? 'playoff-cutoff-line' : ''}`}>
                                <th scope="row">{index + 1}</th>
                                <td>{player.id}</td>
                                { player.weeklyTotals.map((total, index) => (<td key={`weekTotal${index}`} className="d-none d-lg-table-cell text-center">{total}</td>)) }
                                <td className="text-center text-bold">{player.total}</td>
                                <td className="text-center">{player.pointsBehindLeader ? player.pointsBehindLeader : '-'}</td>
                                <td className="text-center">{player.pointsOutOfPlayoffs ? player.pointsOutOfPlayoffs : '-'}</td>
                            </tr>
                        ))
                    }
                </tbody>
            </table>
            <div className="legend font-italic text-center">
                1st Tiebreaker: Season Cash Total<br />
                2nd Tiebreaker: Season High Weekly Point Total
            </div>
        </div>
    );
}

function CashLeaders(props) {
    if (!props.leaders) {
        return <div></div>;
    }
    const userId = props.user ? props.user.id : null;
    return (
        <table className="table table-striped table-sm">
        <thead className="thead-inverse">
            <tr>
                <th>#</th>
                <th>Player</th>
                <WeekColumns weekCount={props.weekCount}></WeekColumns>
                <th className="text-center">Total</th>
                <th className="text-center">Playoffs</th>
            </tr>
        </thead>
        <tbody>
            {
                props.leaders.map((player, index) => (
                    <tr key={`cashleader${index}`} className={`${userId === player.id ? 'active-user' : ''} ${index === props.playoffLineIndex ? 'playoff-cutoff-line' : ''}`}>
                        <th scope="row">{index + 1}</th>
                        <td>{player.id}</td>
                        { player.weeklyTotals.map((total, index) => (<td key={`weekCashTotal${index}`} className="d-none d-lg-table-cell text-center">${total}</td>)) }
                        <td className="text-center text-bold">${player.overallTotal}</td>
                        <td className="text-center">{player.playoffTotal ? `$${player.playoffTotal}` : '-'}</td>
                    </tr>
                ))
            }
        </tbody>
    </table>
    );
}

class Leaderboards extends React.Component {
    constructor(props) {
        super(props);

        this.handleChangeView = this.handleChangeView.bind(this);

        this.state = {
            loading: true,
            filter: 'point',
            user: null, 
            pointLeaders : null,
            cashLeaders: null,
            playoffLineIndex: null,
            weekCount: null
        };
    }

    handleChangeView(e) {
        this.setState({
            filter: e.target.getAttribute('data-target')
        })
    }

    componentDidMount() {
        const webServices = ['/api/league/leaders/points/', '/api/league/leaders/cash/'];
        const promiseURLs = webServices.map(url => fetch(url,
            {
                cache: "no-cache",
                headers: {
                    'Accept': 'application/json'
                }
            }).then(res => res.json()));
        
        Promise.all(promiseURLs)
            .then(responses => {
                this.setState( {
                    loading: false,
                    pointLeaders: responses[0].pointLeaders,
                    cashLeaders: responses[1].cashLeaders,                    
                    playoffLineIndex: responses[0].site.numPlayoffTeams + 1,
                    weekCount: responses[0].site.weekCount,
                });
            });
    }

    render() {
        return (
            <div className="container mt-4">
                <div className="row">
                    <div className="col">
                        <div className="m-leaderboards" >
                            <div className="btn-group btn-group-sm text-center mb-3" role="group" aria-label="Choose Leaderboard">
                                <button type="button" className={`btn btn-default ${this.state.filter === 'point' ? 'active' : ''}`} onClick={this.handleChangeView} data-target="point">Point Leaders</button>
                                <button type="button" className={`btn btn-default ${this.state.filter === 'cash' ? 'active' : ''}`} onClick={this.handleChangeView}  data-target="cash">Cash Leaders</button>
                            </div>
                            {
                                this.state.filter === 'point' ?
                                    <PointLeaders 
                                        leaders={this.state.pointLeaders}
                                        user={this.state.user}
                                        playoffLineIndex={this.state.playoffLineIndex}
                                        weekCount={this.state.weekCount}>
                                        </PointLeaders>
                                :
                                    <CashLeaders
                                        leaders={this.state.cashLeaders}
                                        user={this.state.user}
                                        weekCount={this.state.weekCount}>
                                    </CashLeaders>
                            }
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
  

export default Leaderboards;