import React from 'react';
import './WeekPreview.scss';
import WeekPaginator from './WeekPaginator';

function Game(props) {
    const game = props.game;
    return (
        <div className={`carousel-item ${ props.active ? 'active' : ''}`}>
            <div className="row">
                <div className="col-5 text-center">
                    <img alt="" className="pl-3 game-helmet" src={`./img/helmets/128/${game.home.id}.png`} />
                    <div className="game-team">{game.home.name}</div>
                    <div className="game-record">{game.result ? game.result[game.home.id] : game.home.record}</div>
                </div>
                <div className="col-2">
                    <div class="separator">
                        <div>@</div>
                    </div>
                </div>
                <div className="col-5 text-center">
                    <img alt="" className="pr-3 game-helmet" src={`./img/helmets/128/${game.away.id}.png`} />
                    <div className="game-team">{game.away.name}</div>
                    <div className="game-record">{game.result ? game.result[game.away.id] : game.away.record}</div>
                </div>
            </div>
            <div className="row">
                { game.result ? 
                    <div className="col text-center">
                        <hr className="mb-3" />
                        <span className="font-weight-bold">FINAL</span><br />
                        <span className="font-weight-bold">&nbsp;</span>
                    </div>
                :
                    <div className="col text-center">
                        <hr className="mb-3" />
                        <span className="font-weight-bold">Kickoff:</span> {game.info.kickoff}<br />
                        <span className="font-weight-bold">Venue:</span> {game.info.venue}
                    </div>
                }
            </div>
        </div>
    );
}

function GameList(props) {
    if (props.games) {
        const games = props.games.map((game, index) => ( <Game key={`game${index}`} game={game} active={index === 0}></Game> ));
        return games;
    }
    return <div></div>;
}

class WeekPreview extends React.Component {
    constructor(props) {
        super(props);

        this.handleClick = this.handleClick.bind(this);

        // the user here will be shared by other pages/components throughout the app
        this.state = {
            loading: true,
            games: null,
            site: {}
        };
    }

    getSchedule(week) {
        let url = '/api/nfl/schedule/';
        if (week) {
            url += `?week=${week}`
        }

        // only make this call if we have a valid user.
        fetch(url,
        {
            cache: "no-cache",
            headers: {
                'Accept': 'application/json'
            }
        })
        .then(res => res.json())
        .then(
            (response) => {
                this.setState({
                    loading: false,
                    games: response.schedule,
                    site: response.site,
                });
            },
            // Note: it's important to handle errors here
            // instead of a catch() block so that we don't swallow
            // exceptions from actual bugs in components.
            (error) => {
                this.setState({
                    loading: false
                });
            }
        );
    }

    handleClick(e) {
        this.getSchedule(e.target.getAttribute('data-week'));

        e.preventDefault();
    }

    componentDidMount() {
        this.getSchedule();
    }

    render() {
        return (
            <div className="m-week-preview panel mb-3">
                <h6 className="text-center">Week {this.state.site.requestedWeek} Previews</h6>
                <hr />
                <WeekPaginator
                    weekCount={this.state.site.weekCount}
                    requestedWeek={this.state.site.requestedWeek}
                    handleClick={this.handleClick}>
                </WeekPaginator>
                { (!this.state.games || this.state.games.length === 0) ?
                    <div class="no-games mt-5 text-center">
                        { this.state.games && "The schedule for this week is not yet available."}
                    </div>
                :
                    <div id="game-carousel" className="carousel slide px-5" data-ride="carousel">
                        <div className="carousel-inner">
                            <GameList games={this.state.games}></GameList>
                        </div>
                        <a className="carousel-control-prev" href="#game-carousel" role="button" data-slide="prev">
                            <span>
                            <svg width="32px" height="32px" viewBox="0 0 16 16" className="bi bi-arrow-left-square" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                <path fillRule="evenodd" d="M14 1H2a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z"/>
                                <path fillRule="evenodd" d="M8.354 11.354a.5.5 0 0 0 0-.708L5.707 8l2.647-2.646a.5.5 0 1 0-.708-.708l-3 3a.5.5 0 0 0 0 .708l3 3a.5.5 0 0 0 .708 0z"/>
                                <path fillRule="evenodd" d="M11.5 8a.5.5 0 0 0-.5-.5H6a.5.5 0 0 0 0 1h5a.5.5 0 0 0 .5-.5z"/>
                            </svg>
                            </span>
                            <span className="sr-only">Previous</span>
                        </a>
                        <a className="carousel-control-next" href="#game-carousel" role="button" data-slide="next">
                            <span>
                                <svg width="32px" height="32px" viewBox="0 0 16 16" className="bi bi-arrow-right-square" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                    <path fillRule="evenodd" d="M14 1H2a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z"/>
                                    <path fillRule="evenodd" d="M7.646 11.354a.5.5 0 0 1 0-.708L10.293 8 7.646 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708 0z"/>
                                    <path fillRule="evenodd" d="M4.5 8a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1H5a.5.5 0 0 1-.5-.5z"/>
                                </svg>
                            </span>
                            <span className="sr-only">Next</span>
                        </a>
                    </div>
                }
            </div>
        );
    }
}

export default WeekPreview;