import React from 'react';
import WeekPaginator from '../../components/WeekPaginator';
import Game from '../components/Game';

class ManageSchedule extends React.Component {
    constructor(props) {
        super(props);
        this.handleWeekSelected = this.handleWeekSelected.bind(this);
        this.buildSchedule = this.buildSchedule.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);

        const defaultFormValues = {};
        
        for (let i=1; i <= 16; ++i) {
            defaultFormValues[`home${i}`] = '';
            defaultFormValues[`away${i}`] = '';
            defaultFormValues[`venue${i}`] = 'HOME';
            defaultFormValues[`date${i}`] = '';
            defaultFormValues[`gameId${i}`] = '';
        }

        this.state = {
            loading: true,
            teams: null,
            site: {},
            statusMessage: null,
            formValues: {...defaultFormValues},
            defaultFormValues
        };
    }

    handleInputChange(e) {
        const formValues = { ...this.state.formValues };
        formValues[e.target.name] = e.target.value;
        this.setState({
            formValues
        });
    }

    buildSchedule() {
        const gameCount = this.state.site.maxGamesPerWeek;
        let games = [];

        for (let i = 1; i <= gameCount; ++i) {
            games.push(
                <div key={`game${i}`} className="row">
                    <div className="col">
                        <Game teams={this.state.teams} index={i} formValues={this.state.formValues} handleInputChange={this.handleInputChange}></Game>
                    </div>
                </div>
            );
        }

        return games;
    }

    getSchedule(week) {
        let url = '/api/admin/manage-schedule/';
        if (week) {
            url += `?week=${week}`
        }

        // only make this call if we have a valid user.
        fetch(url,
        {
            cache: "no-cache",
            credentials: 'same-origin', // This is required to send the cookies in the request.
            headers: {
                'Accept': 'application/json'
            }
        })
        .then(res => res.json())
        .then(
            (response) => {
                const formValues = {...this.state.defaultFormValues};
                
                // if we havce a schedule with this response, then update the form values with the selections from the schedule.
                if (response.schedule) {
                    response.schedule.forEach((game, index) => {
                        formValues[`gameId${index + 1}`] = game.id;
                        formValues[`home${index + 1}`] = game.home;
                        formValues[`away${index + 1}`] = game.away;
                        formValues[`venue${index + 1}`] = game.venue;
                        formValues[`date${index + 1}`] = game.kickoff;
                    })
                };

                this.setState({
                    teams: response.teams,
                    site: response.site,
                    formValues
                });
            },
            // Note: it's important to handle errors here
            // instead of a catch() block so that we don't swallow
            // exceptions from actual bugs in components.
            (error) => {
            }
        );
    }

    handleWeekSelected(e) {
        this.getSchedule(e.target.getAttribute('data-week'));

        e.preventDefault();
    }

    handleSubmit(e) {
        let formData = new FormData();
        
        formData.append('week', this.state.site.requestedWeek);
        for (let property in this.state.formValues) {
            formData.append(property, this.state.formValues[property]);
         }

        // only make this call if we have a valid user.
        fetch('/api/admin/manage-schedule/',
        {
            method: "POST",
            cache: "no-cache",
            credentials: 'same-origin', // This is required to send the cookies in the request.
            body: formData,
            headers: {
                'Accept': 'application/json'
            }
        })
        .then(res => res.json())
        .then(
            (response) => {
                let statusMessage;

                if (response.status === 202) {
                    statusMessage = <div>
                        Successfully added {response.site.numGamesAdded} games.<br />
                        Successfully modified {response.site.numGamesModified} games.<br />
                    </div>;
                } else {
                    statusMessage = response.message;
                }

                this.setState({ statusMessage });
            },
            // Note: it's important to handle errors here
            // instead of a catch() block so that we don't swallow
            // exceptions from actual bugs in components.
            (error) => {
                this.setState({
                    loading: false
                });
            }
        );

        e.preventDefault();
    }

    componentDidMount() {
        this.getSchedule();
    }

    render() {
        return (
            <div className="container mt-4">
                <div className="row">
                    <div className="col">
                        <h4 className="title">Manage Schedule</h4>
                        <hr />
                    </div>
                </div>
                <div className="row mb-3">
                    <div className="col">
                    <WeekPaginator
                        weekCount={this.state.site.weekCount}
                        requestedWeek={this.state.site.requestedWeek}
                        handleClick={this.handleWeekSelected}>
                        </WeekPaginator>
                    </div>
                </div>
                <div className="row mb-3">
                    <div className="col text-center">
                        { this.state.statusMessage }
                    </div>
                </div>
                <form onSubmit={this.handleSubmit}>
                    {
                        this.buildSchedule()
                    }
                    <div className="row">
                        <div className="col text-center">
                            <button type="submit" className="btn btn-primary">Submit</button>
                        </div>
                    </div>
                </form>
            </div>
        );
    }
}
  

export default ManageSchedule;