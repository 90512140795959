
import React from 'react';
import PlayerCard from '../components/PlayerCard';
import AddNewPlayerModal from '../components/AddNewPlayerModal';


class ManagePlayers extends React.Component {
    constructor(props) {
        super(props);
        this.handleUpdateFilter = this.handleUpdateFilter.bind(this);
        this.handlePlayerUpdate = this.handlePlayerUpdate.bind(this);
        this.refreshPlayers = this.refreshPlayers.bind(this);
        this.filterPlayers = this.filterPlayers.bind(this);

        this.state = {
            originalPlayers: null,
            players : null,
            filter: '',
            active: false,
            paid: false
        };
    }

    filterPlayers(players, filter, active, paid) {
        return players.filter((player) => {
            return ((!active || (active && player.isActive)) &&
                    (!paid || (paid && !player.isPaid)) &&
                    (!filter ||
                    (player.name.toLowerCase().indexOf(filter.toLowerCase()) >= 0) ||
                    (player.firstName.toLowerCase().indexOf(filter.toLowerCase()) >= 0) ||
                    (player.lastName.toLowerCase().indexOf(filter.toLowerCase()) >= 0)));
        })
    }

    handlePlayerUpdate(userId, isActive, isPaid) {
        let requestData = {
            userId,
            active: isActive,
            paid: isPaid
        }

        // get the current user, if valid.
        fetch("/api/admin/manage-players/",
            {
                method: 'PUT',
                cache: "no-cache",
                credentials: 'same-origin', // This is required to send the cookies in the request.
                body: JSON.stringify(requestData),
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                }
            })
        .then(res => res.json())
        .then((response) => {
            if (response.status === 200) {
                const currentFilter = this.state.filter;
                const currentActive = this.state.active;
                const currentPaid = this.state.paid;
                // if successful update the player in our original player list and apply our filter if one set.
                const updatedPlayers = this.state.originalPlayers.map((player) => {
                    if (player.name === userId) {
                        return { ...player, isActive, isPaid };
                    }
                    return player;
                })

                this.setState({
                    originalPlayers: updatedPlayers,
                    players: this.filterPlayers([...updatedPlayers], currentFilter, currentActive, currentPaid)
                });
            }
        },
            // Note: it's important to handle errors here
            // instead of a catch() block so that we don't swallow
            // exceptions from actual bugs in components.
            (error) => {
                console.log('error=' + JSON.stringify(error));
            }
        )
    }

    handleUpdateFilter(e) {
        let filter = this.state.filter;
        let active = this.state.active;
        let paid = this.state.paid;

        if (e.currentTarget.name === 'active') {
            active = e.currentTarget.checked;
            this.setState({
                active,
                players: this.filterPlayers([...this.state.originalPlayers], filter, active, paid)
            });

        } else if (e.currentTarget.name === 'paid') {
            paid = e.currentTarget.checked;
            this.setState({
                paid,
                players: this.filterPlayers([...this.state.originalPlayers], filter, active, paid)
            });
        } else {
            filter = e.currentTarget.value;
            this.setState({
                filter,
                players: this.filterPlayers([...this.state.originalPlayers], filter, active, paid)
            });
        }
    }

    refreshPlayers() {
        fetch("/api/admin/manage-players/",
        {
            cache: "no-cache",
            credentials: 'same-origin', // This is required to send the cookies in the request.
            headers: {
                'Accept': 'application/json'
            }
        })
        .then(res => res.json())
        .then(
            (response) => {
                const currentFilter = this.state.filter;
                const currentActive = this.state.active;
                const currentPaid = this.state.paid;
                this.setState({
                    originalPlayers: response.players,
                    players: this.filterPlayers(response.players, currentFilter, currentActive, currentPaid)
                });
            },
            // Note: it's important to handle errors here
            // instead of a catch() block so that we don't swallow
            // exceptions from actual bugs in components.
            (error) => {
            }
        );
    }

    componentDidMount() {
        this.refreshPlayers()
    }

    render() {
        return (
            <div className="container mt-4">
                <AddNewPlayerModal refreshPlayers={this.refreshPlayers}></AddNewPlayerModal>
                <div className="row">
                    <div className="col">
                        <h4 className="title">Manage Players</h4>
                        <hr />
                    </div>
                </div>
                <div className="row mb-3">
                    <div className="col-9 col-md-6">
                        <div className="input-group">
                            <div className="input-group-prepend">
                                <span className="input-group-text" id="basic-addon1">
                                    <svg width="1em" height="1em" viewBox="0 0 16 16" className="bi bi-search" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                        <path fillRule="evenodd" d="M10.442 10.442a1 1 0 0 1 1.415 0l3.85 3.85a1 1 0 0 1-1.414 1.415l-3.85-3.85a1 1 0 0 1 0-1.415z"/>
                                        <path fillRule="evenodd" d="M6.5 12a5.5 5.5 0 1 0 0-11 5.5 5.5 0 0 0 0 11zM13 6.5a6.5 6.5 0 1 1-13 0 6.5 6.5 0 0 1 13 0z"/>
                                    </svg>
                                </span>
                            </div>
                            <input type="text" name="filter" className="form-control" placeholder="Search/Filter Players" aria-label="Search/Filter" aria-describedby="basic-addon1" value={this.state.filter} onChange={this.handleUpdateFilter} />
                        </div>
                    </div>
                    <div className="col-3 col-md-6 text-right">
                        <button name="add" type="button" className="btn btn-primary btn-sm" data-toggle="modal" data-target="#add-player-modal">Add Player</button>
                    </div>
                </div>
                <div className="row mb-1">
                    <div className="col-12">
                        <label className="btn btn-default mr-2">
                            <input name="active"
                                type="checkbox"
                                checked={this.state.active}
                                onChange={this.handleUpdateFilter}/> Show Active
                        </label>
                        <label className="btn btn-default">
                            <input name="paid"
                                type="checkbox"
                                checked={this.state.paid}
                                onChange={this.handleUpdateFilter}/> Not Paid
                        </label>
                    </div>
                </div>
                { this.state.players && 
                    <div className="row">
                        { this.state.players.map((player, index) => (
                            <div key={`player${index}`} className="col-12 col-md-6 col-lg-4">
                                <PlayerCard player={player} handlePlayerUpdate={this.handlePlayerUpdate}></PlayerCard>
                            </div>
                        ))
                        }
                    </div>
                }
            </div>
        );
    }
}

export default ManagePlayers;