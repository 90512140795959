import React from 'react';
import SignInForm from './SignInForm';
import ForgotPasswordForm from './ForgotPasswordForm';
import ForgotPasswordConfirmation from './ForgotPasswordConfirmation';

class SignInModal extends React.Component {
    constructor(props) {
        super(props);
        this.changeView = this.changeView.bind(this);

        this.state = {
            view: 'signin'
        }
    }

    changeView(view) {
        this.setState({
            view: view || 'signin'
        });
    }

    render() {
        return (
            <div className="modal fade" id="log-in-modal" tabIndex="-1" role="dialog" aria-labelledby="log-in-label">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        { this.state.view === 'signin' &&
                            <SignInForm changeView={this.changeView}></SignInForm>
                        }
                        { this.state.view === 'forgotpassword' &&
                            <ForgotPasswordForm changeView={this.changeView}></ForgotPasswordForm>
                        }
                        { this.state.view === 'confirmation' &&
                            <ForgotPasswordConfirmation changeView={this.changeView}></ForgotPasswordConfirmation>
                        }
                    </div>
                </div>
            </div>
        );
    }
}

export default SignInModal;