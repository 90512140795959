import React from 'react';
import Matchup from './Matchup';

const DRAG_THRESHOLD = .40;
const SWAP_SPEED = 300;

class MatchupContainer extends React.Component {
    constructor(props) {
        super(props);

        this.dragState = {
            dragSourceId: null,
            dragTargetId: null,
            dragLastOffsetY: 0
        };

        this.handleDragStart = this.handleDragStart.bind(this);
        this.handleDragEnd = this.handleDragEnd.bind(this);
        this.handleDragEnter = this.handleDragEnter.bind(this);
        this.handleDragOver = this.handleDragOver.bind(this);
        this.handleDrop = this.handleDrop.bind(this);
        this.handleMoveUp = this.handleMoveUp.bind(this);
        this.handleMoveDown = this.handleMoveDown.bind(this);
        this.doAnimationSwap = this.doAnimationSwap.bind(this);
    }

    swapGames(sourceId, targetId) {
        const schedule = [...this.props.schedule];
        let sourceIndex = schedule.findIndex((game) => (game.info.id === sourceId));
        let targetIndex = schedule.findIndex((game) => (game.info.id === targetId));

        let temp = schedule[sourceIndex];
        schedule[sourceIndex] = schedule[targetIndex];
        schedule[targetIndex] = temp;

        this.props.updateSchedule(schedule);
    }

    handleDragStart(e) {
        this.dragState.dragSourceId = e.currentTarget.getAttribute('data-id');
        this.dragState.dragLastOffsetY = e.pageY;

        // we use the data here simply to activate the drag API, which is required to be set for drag to initiate in some browsers.
        // to access the dataTransfer object, we need to access the native event, not the jQuery wrapper.
        try {
            // Don't remove this as FF requires this to be set for drag/drop to work.
            // e.dataTransfer.setData('text/plain', this.dragDrop.sourceId);
            e.dataTransfer.dropEffect = 'move';
        } catch (err) {
            // no op...MS Edge no longer supports call to set data, so need to catch it and not err.
        }
    }

    handleDragEnter(e) {
        const target = e.currentTarget;
        const source = document.querySelector(`[data-id="${this.dragState.dragSourceId}"]`);
        if (!target.isSameNode(source) && target.getAttribute('data-locked') === 'false') {
            this.dragState.dragTargetId = target.getAttribute('data-id');
        }
    }

    handleDragOver(e) {
        if (this.dragState.dragTargetId) {
            const target = document.querySelector(`[data-id="${this.dragState.dragTargetId}"]`);
            const rect = target.getBoundingClientRect();
            const offsetTop = rect.top + window.scrollY;

            if (e.pageY > this.dragState.dragLastOffsetY) {
                // if we passed the threshold while moving DOWN
                if (e.pageY > (offsetTop + (target.offsetHeight * DRAG_THRESHOLD))) {
                    this.swapGames(this.dragState.dragSourceId, this.dragState.dragTargetId);
                    this.dragState.dragTargetId = null;
                    this.dragState.dragLastOffsetY = 0;
                }
            } else if (e.pageY < this.dragState.dragLastOffsetY) {
                // if we passed the threshold while moving UP
                if (e.pageY < (offsetTop + (target.offsetHeight * (1.0 - DRAG_THRESHOLD)))) {
                    this.swapGames(this.dragState.dragSourceId, this.dragState.dragTargetId);
                    this.dragState.dragTargetId = null;
                    this.dragState.dragLastOffsetY = 0;
                }
            }
        }

        this.dragState.dragLastOffsetY = e.pageY;        

        // Don't remove this.  React requires this for the drop event to be received.
        e.preventDefault();
    }

    handleDrop(e) {
        try {
            // Don't remove this as FF requires this to be set for drag/drop to work.
            e.dataTransfer.clearData();
        } catch (err) {
            // no op...MS Edge no longer supports call to clearData, so need to catch it and not err.
        }
    }

    handleDragEnd(e) {
        this.dragState.dragSourceId = null;
        this.dragState.dragTargetId = null;
        this.dragState.dragLastOffsetY = 0;
    }

    doAnimationSwap(source, target, offsetMultiplier) {
        source.animate([ 
            { top: 0 },
            { top: `-${offsetMultiplier * source.offsetHeight}px` }
        ],
        { duration: SWAP_SPEED });

        target.animate([ 
            { top: 0 },
            { top: `${offsetMultiplier * target.offsetHeight}px` }
        ],
        { duration: SWAP_SPEED });
        
        setTimeout(() => {
            this.swapGames(source.getAttribute('data-id'), target.getAttribute('data-id'));
        }, SWAP_SPEED);
    }

    // these methods are for the mobile view where drag/drop is not available.
    handleMoveUp(e) {
        const matchup = e.currentTarget.closest('[data-js="matchup"]');
        const matchups = document.querySelectorAll('[data-js="matchup-container"] [data-js="matchup"]');
        const sourceIndex = [...matchups].indexOf(matchup);
        let offsetCount = 0;

        for (let i = (sourceIndex - 1); i >= 0; --i) {
            offsetCount++;
            if (matchups[i].getAttribute('data-locked') !== 'true') {
                this.doAnimationSwap(matchup, matchups[i], offsetCount);
                break;
            }
        }

        e.stopPropagation();
        e.preventDefault();
    }

    handleMoveDown(e) {
        const matchup = e.currentTarget.closest('[data-js="matchup"]');
        const matchups = document.querySelectorAll('[data-js="matchup-container"] [data-js="matchup"]');
        const sourceIndex = [...matchups].indexOf(matchup);
        let offsetCount = 0;

        for (let i = (sourceIndex + 1); i < matchups.length; ++i) {
            offsetCount++;
            if (matchups[i].getAttribute('data-locked') !== 'true') {
                this.doAnimationSwap(matchups[i], matchup, offsetCount);
                break;
            }
        }

        e.stopPropagation();
        e.preventDefault();
    }

    render() {
        const schedule = this.props.schedule;
        const playoffSeed = this.props.playoffSeed;
        return (
            <div className="matchups" data-js="matchup-container">
                { schedule && 
                    schedule.map((game, index) => (
                        <Matchup 
                            key={game.info.id}
                            handleDragOver={this.handleDragOver}
                            handleDragStart={this.handleDragStart}
                            handleDragEnd={this.handleDragEnd}
                            handleDragEnter={this.handleDragEnter}
                            handleDrop={this.handleDrop}
                            handleMoveUp={this.handleMoveUp}
                            handleMoveDown={this.handleMoveDown}
                            game={game}
                            tiebreaker={this.props.tiebreaker}
                            isPlayoffs={this.props.isPlayoffs}
                            showPlayoffCutoff={playoffSeed === (index + 1)}
                        >
                        </Matchup>
                    ))
                }
            </div>
        );
    }
}

export default MatchupContainer;