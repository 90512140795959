import React from 'react';

class ForgotPasswordConfirmation extends React.Component {
    constructor(props) {
        super(props);
        this.handleChangeView = this.handleChangeView.bind(this);
    }

    handleChangeView(e) {
        this.props.changeView();
    }
    
    render() {
        return (
            <div class="forgot-password-confirmation">
                <div class="modal-header">
                    <h4 class="modal-title">Password Retrieved</h4>
                    <button type="button" className="close" data-dismiss="modal" onClick={this.handleChangeView} aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    Your password has been sent to your email on record.
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-default" data-dismiss="modal" onClick={this.handleChangeView} >OK</button>
                </div>
            </div>
        );
    }
}

export default ForgotPasswordConfirmation;