import React, { useState } from 'react';

function LockPlayoffUsersModal(props) {
    const messageInitial = "Are you sure you want to lock the playoff users?  This should only run this once the final results are in for the last week of the regular season so all tiebreakers can be applied.";
    const [ disabled, setDisabled ] = useState(false);
    const [ message, setMessage ] = useState(messageInitial);

    const handleReset = () => {
        setDisabled(false);
        setMessage(messageInitial);
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        setDisabled(true);
        setMessage('Running...');

        const data = await fetch('/api/league/leaders/points/', { cache: "no-cache", headers: { 'Accept': 'application/json' }}).then(res => res.json());
        const users = data.pointLeaders.map((user) => ({ 
            id: user.id,
        }));

        await fetch("/api/admin/lock-playoff-users/",
        {
            method: "POST",
            cache: "no-cache",
            credentials: 'same-origin', // This is required to send the cookies in the request.
            body: JSON.stringify({ users }),
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        })
        .then(res => res.json())
        .then((response) => {
                if (response.status === 202) {
                    setMessage(`Successfully locked the playoff users for the ${response.site.currentSeason} season.`);
                } else {
                    setMessage(response.message);
                }
            },
            // Note: it's important to handle errors here
            // instead of a catch() block so that we don't swallow
            // exceptions from actual bugs in components.
            (error) => {
            }
        );
    };

    return (
        <div className="modal fade" id="lock-playoff-users-modal" tabIndex="-1" role="dialog" aria-labelledby="lock-playoff-users-label">
            <div className="modal-dialog" role="document">
                <div className="modal-content">
                <form onSubmit={handleSubmit}>
                <div className="modal-header">
                    <h4 className="modal-title">Confirm Lock Playoff Users</h4>
                    <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={handleReset}>
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div className="modal-body">
                    <div>
                        {message}
                    </div>
                </div>
                <div className="modal-footer">
                    <button type="button" className="btn btn-default" data-dismiss="modal" onClick={handleReset}>Take me back!</button>
                    <button type="submit" disabled={disabled} className={`btn btn-danger ${disabled ? 'disabled' : ''}`}>Yup, I'm Good!</button>
                </div>
            </form>
                </div>
            </div>
        </div>
    )
}

export default LockPlayoffUsersModal;