import React from 'react';
import './NflStandings.scss';

function Team(props) {
    const team = props.team;
    return (
        <tr>
            <td>{team.name}</td>
            <td>{team.wins}</td>
            <td>{team.losses}</td>
            <td>{team.ties}</td>
            <td className="d-none d-sm-table-cell">{team.streak}</td>
            <td className="d-none d-sm-table-cell">{team.pointsFor}</td>
            <td className="d-none d-sm-table-cell">{team.pointsAgainst}</td>
            <td>{team.homeRecord}</td>
            <td>{team.awayRecord}</td>
            <td>{team.divisionRecord}</td>
            <td>{team.conferenceRecord}</td>
        </tr>
    )
}

function Division(props) {
    const teams = props.teams.map((team, index) => (
        <Team key={`team${index}`} team={team}></Team>
    ));

    return (
        <div class="col-12 col-lg-6">
            <table className="m-nfl-division table table-sm">
                <thead className="thead-inverse">
                    <tr>
                        <th colspan="11" class="text-center">{props.conference} {props.name}</th>
                    </tr>
                    <tr>
                        <th>Team</th>
                        <th>W</th>
                        <th>L</th>
                        <th>T</th>
                        <th className="d-none d-sm-table-cell">STK</th>
                        <th className="d-none d-sm-table-cell">PF</th>
                        <th className="d-none d-sm-table-cell">PA</th>
                        <th>Home</th>
                        <th>Road</th>
                        <th>DIV</th>
                        <th>CNF</th>
                    </tr>
                </thead>
                <tbody>
                    {teams}
                </tbody>
            </table>
        </div>
    );
}

function Conference(props) {
    const divisions = props.divisions.map((division) => (
        <Division teams={division.teams} conference={division.conference} name={division.name}></Division>
    ));

    return divisions;
}

class NflStandings extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            conferences : null
        };
    }

    componentDidMount() {
        // only make this call if we have a valid user.
        fetch("/api/nfl/standings/",
        {
            cache: "no-cache",
            headers: {
                'Accept': 'application/json'
            }
        })
        .then(res => res.json())
        .then(
            (response) => {
                this.setState({
                    loading: false,
                    conferences: response.standings.conferences
                });
            },
            // Note: it's important to handle errors here
            // instead of a catch() block so that we don't swallow
            // exceptions from actual bugs in components.
            (error) => {
                this.setState({
                    loading: false
                });
            }
        );
    }

    render() {
        if (!this.state.loading) {
            const conferences = this.state.conferences.map((conference) => (
                <div className="row">
                    <Conference divisions={conference.divisions}></Conference>
                </div>
            ));
            
            return (
                <div className="container mt-4">
                    <div className="row">
                        <div className="col">
                            <h4 className="title">NFL Standings</h4>
                        </div>
                    </div>
                    {conferences}
                </div>
            );
        }
        return <div></div>
    }
}
  

export default NflStandings;