import React from 'react';

function Game(props) {
    return (
        <div className="panel mb-3 game">
            <div className="row">
                <div className="col">
                    <h6 className="title">Game {props.index}</h6>
                </div>
            </div>
            <div className="row">
                <div className="col-12 col-md-6 col-lg-3">
                    <label>Away</label>
                    <select name={`away${props.index}`} value={props.formValues[`away${props.index}`]} onChange={props.handleInputChange} className="form-control" >
                        <option key="teamAway0" value="">-</option>
                        { props.teams.map((team, index) => (
                            <option key={`teamAway${index}`} value={team.id}>{team.name}</option>
                        ))}
                    </select>
                </div>
                <div className="col-12 col-md-6 col-lg-3">
                    <label>Home</label>
                    <select name={`home${props.index}`} value={props.formValues[`home${props.index}`]} onChange={props.handleInputChange} className="form-control" >
                        <option key="teamHome0" value="">-</option>
                        { props.teams.map((team, index) => (
                            <option key={`teamHome${index}`} value={team.id}>{team.name}</option>
                        ))}
                    </select>
                </div>
                <div className="col-12 col-md-6 col-lg-3">
                    <label>Venue</label>
                    <input name={`venue${props.index}`} type="text" list="venues" value={props.formValues[`venue${props.index}`]} onChange={props.handleInputChange} className="form-control"/>
                    <datalist id="venues">
                        <option>HOME</option>
                        <option>AWAY</option>
                    </datalist>
                </div>
                <div className="col-12 col-md-6 col-lg-3">
                    <label>Kickoff (CST)</label>
                    <input name={`date${props.index}`} type="text" value={props.formValues[`date${props.index}`]} onChange={props.handleInputChange} className="form-control" placeholder="YYYY-MM-DD HH:MM:SS" />
                </div>
            </div>
        </div>
    );
}

export default Game