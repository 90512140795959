import React from 'react';
import './PlayoffBracket.scss';
import PlayoffMatchup from './PlayoffMatchup';

function PlayoffBracketSuperBowl(props) {
    const afc = props.schedule.conference['afc'];
    const nfc = props.schedule.conference['nfc'];
    const superBowl = props.schedule.superbowl;
    let champ;

    // see if we have a champion.
    if (superBowl.home && superBowl.away) {
        if (superBowl.home.score !== superBowl.away.score) {
            champ = Number(superBowl.home.score) > Number(superBowl.away.score) ? superBowl.home : superBowl.away;
        }
    }

    return (
        <div className={`playoff-bracket`}>
            <div className="headers">
                <h6>NFC<br/>Championship</h6>
                <h6>
                    <span class="text-center"><img className="d-inline" src="./img/super-bowl-trophy.png" width="75" alt="Super Bowl" /></span>
                </h6>                    
                <h6>AFC<br/>Championship</h6>
            </div>
            <div className="bracket mb-5">
                <div className="round">
                    <PlayoffMatchup conference="nfc" home={nfc.home} away={nfc.away} />
                </div>
                <div className="round mt-5">
                    <PlayoffMatchup conference="superbowl" home={superBowl.home} away={superBowl.away} />
                    <h6 className="text-center mt-5">Champion</h6>
                    <PlayoffMatchup isChampion={true} home={champ} />
                </div>
                <div className="round">
                    <PlayoffMatchup conference="afc" home={afc.home} away={afc.away} />
                </div>
            </div>
        </div>
    )
}

export default PlayoffBracketSuperBowl;