import React, { useState } from 'react';
import './UserProfileForm.scss';

function EditFavorites(props) {
    const [ favorites, setFavorites] = useState(props.userList.map((user) => {
        return {
            id: user.name,
            checked: (props.user.favorites.indexOf(user.name) >= 0)
        }
    }));
    const [ statusMessage, setStatusMessage ] = useState();

    const handleChange = (e) => {
        const updatedFavorites = favorites.map((favorite) => {
            const obj = {
                id: favorite.id,
                checked: favorite.checked
            }

            // if this is our selected item, then update the checked state.
            if (e.target.getAttribute('data-id') === favorite.id) {
                obj.checked = e.target.checked;
            }

            return obj;
        })

        setFavorites(updatedFavorites);
    }

    const handleSubmit = (e) => {
        let formData = new FormData();

        formData.append('userId', props.user.name);

        for (let i = 0; i < favorites.length; ++i) {
            if (favorites[i].checked) {
                formData.append(`favorite${i}`, favorites[i].id);
            }
        }

        fetch("/api/user/favorites/",
            {
                method: "POST",
                cache: "no-cache",
                credentials: 'same-origin', // This is required to send the cookies in the request.
                body: formData,
                headers: {
                    'Accept': 'application/json'
                }
            })
        .then(res => res.json())
        .then((response) => {
                setStatusMessage(response.message);
            },
            // Note: it's important to handle errors here
            // instead of a catch() block so that we don't swallow
            // exceptions from actual bugs in components.
            (error) => {
                console.log('error=' + JSON.stringify(error));
            }
        )

        e.preventDefault();
    }

    return (
        <div className="panel mb-3">
            <form onSubmit={handleSubmit}>
                <input type="hidden" name="user-id" value={props.user.name}/>
                <div className="row">
                    <div className="col">
                        <h6 className="title">Edit Favorites</h6>
                        <hr />
                    </div>
                </div>
                <div className="row">
                    <div className="col-12 text-center status">
                        { statusMessage }
                    </div>
                </div>
                <div className="row favorites">
                    { favorites.map((favorite, index) => (
                        <div key={`favorite${index}`} className="col-6 col-md-4 col-lg-2 mb-3">
                            <input type="checkbox"
                                name={`favorite-${index}`}
                                data-id={favorite.id}
                                checked={favorite.checked}
                                disabled={favorite.id === props.user.name}
                                onChange={handleChange} />
                            <span className="ml-1">{favorite.id}</span>
                        </div>
                    ))
                    }
                </div>
                <div className="row">
                    <div className="col-12 text-center py-3">
                        <button type="submit" className="btn btn-primary">
                            Save Changes
                        </button>
                    </div>
                </div>
            </form>
        </div>
    );
}

function ChangePassword(props) {
    const [ oldPassword, setOldPassword] = useState('');
    const [ newPassword, setNewPassword] = useState('');
    const [ statusMessage, setStatusMessage ] = useState();

    const handleSubmit = (e) => {
        let formData = new FormData();

        formData.append('userId', props.user.name);
        formData.append('password', oldPassword);
        formData.append('confirmPassword', newPassword);

        // get the current user, if valid.
        fetch("/api/user/change-password/",
            {
                method: "POST",
                cache: "no-cache",
                credentials: 'same-origin', // This is required to send the cookies in the request.
                body: formData,
                headers: {
                    'Accept': 'application/json'
                }
            })
        .then(res => res.json())
        .then((response) => {
                setStatusMessage(response.message);
            },
            // Note: it's important to handle errors here
            // instead of a catch() block so that we don't swallow
            // exceptions from actual bugs in components.
            (error) => {
                console.log('error=' + JSON.stringify(error));
            }
        )

        e.preventDefault();
    }

    return (
        <div className="panel mb-3">
            <form name="change-password" onSubmit={handleSubmit}>
                <div className="row">
                    <div className="col">
                        <h6 className="title">Change Password</h6>
                        <hr />
                    </div>
                </div>
                <div className="row">
                    <div className="col-12 text-center status">
                        { statusMessage }
                    </div>
                    <div className="col-12 col-md-6">
                        <label>
                            <div className="font-weight-bold">Old Password<span className="pl-1 font-weight-normal sub-title">(Required)</span></div>
                            <input type="password" autoComplete="current-password" className="form-control" name="password" value={oldPassword} onChange={e => setOldPassword(e.target.value)} maxLength="32" required />
                        </label>
                    </div>
                    <div className="col-12 col-md-6">
                        <label>
                            <div className="font-weight-bold">New Password<span className="pl-1 font-weight-normal sub-title">(Required)</span></div>
                            <input type="password" autoComplete="new-password" className="form-control" name="confirm-password" value={newPassword} onChange={e => setNewPassword(e.target.value)} maxLength="32" required />
                        </label>
                    </div>
                    <div className="col-12 text-center py-3">
                        <button type="submit" className="btn btn-primary">
                            Change Password
                        </button>
                    </div>
                </div>
            </form>
    </div>
    );
}

function UpdatePersonalInfo(props) {
    const [ firstName, setFirstName ] = useState(props.user.firstName);
    const [ lastName, setLastName ] = useState(props.user.lastName);
    const [ email, setEmail ] = useState(props.user.contactEmail);
    const [ paypalEmail, setPaypalEmail ] = useState(props.user.paypalEmail);
    const [ statusMessage, setStatusMessage ] = useState();

    const handleSubmit = (e) => {
        let formData = new FormData();

        formData.append('userId', props.user.name);
        formData.append('firstName', firstName);
        formData.append('lastName', lastName);
        formData.append('email', email);
        formData.append('paypalEmail', paypalEmail);

        // get the current user, if valid.
        fetch("/api/user/profile/",
            {
                method: "POST",
                cache: "no-cache",
                credentials: 'same-origin', // This is required to send the cookies in the request.
                body: formData,
                headers: {
                    'Accept': 'application/json'
                }
            })
        .then(res => res.json())
        .then((response) => {
                setStatusMessage(response.message);
            },
            // Note: it's important to handle errors here
            // instead of a catch() block so that we don't swallow
            // exceptions from actual bugs in components.
            (error) => {
                console.log('error=' + JSON.stringify(error));
            }
        )

        e.preventDefault();
    }

    return (
        <div className="panel mb-3">
            <form onSubmit={handleSubmit}>
                <div className="row">
                    <div className="col">
                        <h6 className="title">Personal Info</h6>
                        <hr />
                    </div>
                </div>
                <div className="row">
                    <div className="col-12 text-center status">
                        { statusMessage }
                    </div>
                    <div className="col-12 col-md-6">
                        <label>
                            <div className="font-weight-bold">First Name<span className="pl-1 font-weight-normal sub-title">(Required)</span></div>
                            <input className="form-control" name="first-name" value={firstName} onChange={e => setFirstName(e.target.value)} maxLength="32" required />
                        </label>
                    </div>
                    <div className="col-12 col-md-6">
                        <label>
                            <div className="font-weight-bold">Last Name<span className="pl-1 font-weight-normal sub-title">(Required)</span></div>
                            <input className="form-control" name="last-name" value={lastName} onChange={e => setLastName(e.target.value)} maxLength="32" required />
                        </label>
                    </div>

                    <div className="col-12 col-md-6">
                        <label>
                            <div className="font-weight-bold">Contact Email<span className="pl-1 font-weight-normal sub-title">(Required)</span></div>
                            <input className="form-control" name="contact-email" maxLength="50" value={email} onChange={e => setEmail(e.target.value)} required />
                        </label>
                    </div>
                    <div className="col-12 col-md-6">
                        <label>
                            <div className="font-weight-bold">PayPal Email<span className="pl-1 font-weight-normal sub-title">(Optional)</span></div>
                            <input className="form-control" name="paypal-email" maxLength="50" value={paypalEmail} onChange={e => setPaypalEmail(e.target.value)}  />
                        </label>
                    </div>
                    <div className="col-12 text-center py-3">
                        <button type="submit" className="btn btn-primary">
                            Save Changes
                        </button>
                    </div>
                </div>
            </form>
        </div>
    );
}

class UserProfileForm extends React.Component {
    constructor(props) {
        super(props);

        this.handleEditFavorites = this.handleEditFavorites.bind(this);
    }

    handleEditFavorites(e) {

    }
    render() {
        return (
            <div className="m-user-profile-form">
                <UpdatePersonalInfo user={this.props.user}></UpdatePersonalInfo>
                <ChangePassword user={this.props.user}></ChangePassword>
                <EditFavorites user={this.props.user} userList={this.props.userList || []} formHandler={this.handleEditFavorites}></EditFavorites>
            </div>
        );
    }
}

export default UserProfileForm;