import React from 'react';

const PLAYOFF_LABEL = "Playoffs";
const PLAYOFF_WEEK = 90;

function CounterItems(props) {
    let items = [];

    for (let i = 1; i <= props.weekCount; ++i) {
        items.push(
        <li key={`week${i}`} className={`page-item ${(props.requestedWeek === i) ? 'active' : ''}`}>
            <a className="page-link" onClick={props.handleClick} data-week={i} href="/">{i}</a>
        </li>
        );
    }

    return items;
}

class WeekPaginator extends React.Component {
    render() {
        const { requestedWeek, showPlayoffs, weekCount, handleClick } = this.props;
        let selectedPrevWeek = null;
        let pagePrevWeek = null;
        let selectedNextWeek = null;
        let pageNextWeek = null;

        // if we're at beginning of this, disable prev, set next.
        if (requestedWeek <= 1) {
            selectedNextWeek = requestedWeek + 1;
            pageNextWeek = requestedWeek + 2;
        // if we're at of last week of regular season, disable next, set prev.
        } else if (!showPlayoffs && (requestedWeek === weekCount)) {
            selectedPrevWeek = requestedWeek - 1;
            pagePrevWeek = requestedWeek - 2;
        // if we're at playoff option, disable next, set prev
        } else if (showPlayoffs && (requestedWeek >= PLAYOFF_WEEK)) {
            selectedPrevWeek = weekCount;
            pagePrevWeek = weekCount - 1;
        // we're somewhere in the middle, set appropriately.
        } else {
            selectedPrevWeek = requestedWeek - 1;
            pagePrevWeek = (requestedWeek - 2) >= 1 ? (requestedWeek - 2) : null;
            selectedNextWeek = ((requestedWeek + 1) > weekCount) ? PLAYOFF_WEEK : (requestedWeek + 1);
            pageNextWeek = (requestedWeek + 2) <= weekCount ? (requestedWeek + 2) : null;

            // if we were past the edge for a regular week, check the edge case for a playoff week.
            if (!pageNextWeek && showPlayoffs && ((requestedWeek + 2) <= (weekCount + 1))) {
                pageNextWeek = PLAYOFF_WEEK;
            }
        }

        return (
            <nav className="m-week-paginator" aria-label="NFL Week">
                <ul className="pagination pagination-sm d-none d-md-flex">
                    <CounterItems
                        weekCount={weekCount}
                        requestedWeek = {requestedWeek}
                        handleClick = {handleClick}>
                    </CounterItems>
                    { showPlayoffs && 
                        <li key={`week${PLAYOFF_WEEK}`} className={`page-item ${(requestedWeek >= PLAYOFF_WEEK) ? 'active' : ''}`}>
                            <a className="page-link" onClick={handleClick} data-week={PLAYOFF_WEEK} href="/">{PLAYOFF_LABEL}</a>
                        </li>
                    }
                </ul>
                <ul className="pagination d-flex d-md-none">
                    <li className={`page-item ${pagePrevWeek ? '' : 'disabled'}`}>
                        <a className="page-link" onClick={handleClick} data-week={pagePrevWeek} href="/">Prev</a>
                    </li>
                    { selectedPrevWeek &&
                        <li className="page-item">
                            <a className="page-link" onClick={handleClick} data-week={selectedPrevWeek} href="/">{selectedPrevWeek}</a>
                        </li>
                    }
                    <li className="page-item active">
                        <a className="page-link" onClick={handleClick} data-week={requestedWeek} href="/">{requestedWeek >= PLAYOFF_WEEK ? 'Playoffs' : requestedWeek}</a>
                    </li>
                    { selectedNextWeek &&
                        <li className="page-item">
                            <a className="page-link" onClick={handleClick} data-week={selectedNextWeek} href="/">{selectedNextWeek >= PLAYOFF_WEEK ? 'Playoffs': selectedNextWeek}</a>
                        </li>
                    }
                    <li className={`page-item ${pageNextWeek ? '' : 'disabled'}`}>
                        <a className="page-link" onClick={handleClick} data-week={pageNextWeek} href="/">Next</a>
                    </li>
                </ul>
            </nav>
        );
    }
}

export default WeekPaginator;