import React from 'react';
import './WeekSnapshot.scss'

class WeekSnapshot extends React.Component {
    constructor(props) {
        super(props);
    
        // the user here will be shared by other pages/components throughout the app
        this.state = {
            loading: true,
            currentWeek: '-',
            numGames: 0,
            nextDeadline: 'N/A',
            numPicksReceived: 0,
            numPicksExpected: 0
        };
    }

    componentDidMount() {
        // only make this call if we have a valid user.
        fetch("/api/league/week-snapshot/",
        {
            cache: "no-cache",
            headers: {
                'Accept': 'application/json'
            }
        })
        .then(res => res.json())
        .then(
            (response) => {
                this.setState({
                    loading: false,
                    currentWeek: response.site.currentWeek,
                    numGames: response.weekSnapshot.numGames,
                    nextDeadline: response.weekSnapshot.nextDeadline,
                    numPicksReceived: response.weekSnapshot.numPicksReceived,
                    numPicksExpected: response.weekSnapshot.numPicksExpected
                });
            },
            // Note: it's important to handle errors here
            // instead of a catch() block so that we don't swallow
            // exceptions from actual bugs in components.
            (error) => {
                this.setState({
                    loading: false
                });
            }
        );
    }

    render() {
        return (
            <div className="week-snapshot panel text-center mb-3">
                <h6 className="title">Current Week</h6>
                <hr />
                <div className="week">{this.state.currentWeek}</div>
                <hr />
                <div>
                    <dl className="stat-box">
                        <dt>Num Games</dt>
                        <dd>{this.state.numGames}</dd>
                        <dt>Next Deadline</dt>
                        <dd>{this.state.nextDeadline}</dd>
                        <dt>Picks Received</dt>
                        <dd>{this.state.numPicksReceived} of {this.state.numPicksExpected}</dd>
                    </dl>
                </div>
            </div>
        );
    }
}

export default WeekSnapshot;