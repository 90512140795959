import React from 'react';

class ForgotPasswordForm extends React.Component {
    constructor(props) {
        super(props);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleChangeView = this.handleChangeView.bind(this);

        this.state = {
            userId: '',
            email: '',
            errorMessage: ''
        };
    }

    handleChangeView(e) {
        // if this is the user trying to dismis dialog, then reset it back to default state when it reopens.
        if (e.currentTarget.getAttribute('data-dismiss') === 'modal') {
            this.props.changeView();
        } else {
            this.props.changeView(e.currentTarget.getAttribute('data-view'));
            e.preventDefault();
            e.stopPropagation();
        }
    }

    handleInputChange(e) {
        this.setState({
          [e.currentTarget.name]: e.currentTarget.value
        });
    }

    componentDidMount() {
        this.setState({
            userId: '',
            email: '',
            errorMessage: ''
        });
    }

    handleSubmit(e) {
        let formData = new FormData();

        formData.append('userid', this.state.userId);
        formData.append('email', this.state.email);

        // get the current user, if valid.
        fetch("/api/user/forgot-password/",
            {
                method: "POST",
                cache: "no-cache",
                credentials: 'same-origin', // This is required to send the cookies in the request.
                body: formData,
                headers: {
                    'Accept': 'application/json'
                }
            })
        .then(res => res.json())
        .then(
            (response) => {
                if (response.status === 202) {
                    this.props.changeView('confirmation');
                } else {
                    this.setState({
                        errorMessage: response.message
                    })
                }
            },
            // Note: it's important to handle errors here
            // instead of a catch() block so that we don't swallow
            // exceptions from actual bugs in components.
            (error) => {
                this.setState({
                    errorMessage: 'There was a problem retrieving your password.'
                })
            }
        )

        e.preventDefault();
    }

    render() {
        return (
            <form onSubmit={this.handleSubmit}>
                <div className="modal-header">
                    <h4 className="modal-title">Forgot Password?</h4>
                    <button type="button" className="close" data-dismiss="modal" onClick={this.handleChangeView} aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div className="modal-body">
                    <div>Please enter required info below to retrieve your password.</div>
                    <div className="form-group error" data-js="error">{this.state.errorMessage}</div>
                    <div className="form-group">
                        <label htmlFor="userid">Username</label>
                        <input type="text" name="userId" className="form-control" value={this.state.userId} onChange={this.handleInputChange} />
                    </div>
                    <div className="form-group">
                        <label htmlFor="email">Email</label>
                        <input type="text" name="email" className="form-control" value={this.state.email} onChange={this.handleInputChange} />
                    </div>
                </div>
                <div className="modal-footer">
                    <button type="button" className="btn btn-default" data-view="signin" onClick={this.handleChangeView}>Back</button>
                    <button type="submit" className="btn btn-primary">Retrieve Password</button>
                </div>
            </form>
        );
    }
}

export default ForgotPasswordForm;