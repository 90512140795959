import React from 'react';
import { Link } from 'react-router-dom';
import './NavBar.scss';
import SignOut from './SignOut';

class NavBar extends React.Component {
    render() {
      return (
        <nav className="navbar navbar-expand-lg navbar-dark bg-dark">
            <a className="navbar-brand" href="/">
                <img className="navbar-logo" alt="Salsero Football" height="100px" src="img/logo.png"/>
            </a>
            <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbar-content" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                <span className="navbar-toggler-icon"></span>
            </button>

            <div className="collapse navbar-collapse" id="navbar-content">
                <ul className="nav navbar-nav mr-auto left-nav">
                    { this.props.user &&
                        <li className="nav-item pl-3 pl-lg-0">
                            <Link className="nav-link" to="/submit-picks">My Picks</Link>
                        </li>
                    }
                    <li className="nav-item pl-3 pl-lg-0">
                        <Link className="nav-link" to="/results">Results</Link>
                    </li>
                    <li className="nav-item pl-3 pl-lg-0">
                        <Link className="nav-link" to="/leaderboards">Leaderboards</Link>
                    </li>
                    <li className="pl-3 pl-lg-0 nav-item dropdown">
                        <a className="nav-link dropdown-toggle" href="/" id="resources-nav" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            NFL Resources
                        </a>
                        <div className="dropdown-menu" aria-labelledby="resources-nav">
                            <Link className="dropdown-item" to="/nfl-schedule">NFL Schedule</Link>
                            <Link className="dropdown-item" to="/nfl-standings">NFL Standings</Link>
                        </div>
                    </li>
                    <li className="pl-3 pl-lg-0 nav-item dropdown">
                        <a className="nav-link dropdown-toggle" href="/" id="resources-nav" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            League
                        </a>
                        <div className="dropdown-menu" aria-labelledby="resources-nav">
                            { this.props.user &&
                                <Link className="dropdown-item" to="/players">Players</Link>
                            }
                            <Link className="dropdown-item" to="/rules">Rules</Link>
                        </div>
                    </li>
                    {/* This is for the mobile views that show the option for profile */}
                    { this.props.user &&
                        <li className="nav-item d-block d-lg-none pl-3 pl-lg-0">
                            <Link className="nav-link" to="/profile">View Profile</Link>
                        </li>
                    }
                    {(this.props.user && this.props.user.adminLevel === 'FULL') && 
                    <li className="pl-3 pl-lg-0 nav-item dropdown">
                        <a className="nav-link dropdown-toggle" href="/" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            Admin
                        </a>
                        <div className="dropdown-menu" aria-labelledby="resources-nav">
                            <Link className="dropdown-item" to="/manage-players">Manage Players</Link>
                            <Link className="dropdown-item" to="/manage-schedule">Manage Schedule</Link>
                            <Link className="dropdown-item" to="/nfl-standings">Enter Results</Link>
                            <Link className="dropdown-item" to="/post-winners">Post Winners</Link>
                            <Link className="dropdown-item" to="/league-settings">Manage League Settings</Link>
                            <Link className="dropdown-item" to="/send-mail">Send Mail</Link>
                            <Link className="dropdown-item" to="/manage-playoff-teams">Manage Playoff Teams</Link>
                        </div>
                    </li>
                    }
                </ul>
                { this.props.user &&
                    <ul className="nav navbar-nav">
                        <li className="nav-item d-none d-lg-block">
                            <span className="navbar-text">Welcome back {this.props.user.name}!&nbsp;&nbsp;
                                <Link to="/profile">
                                    <svg width="24px" height="24px" viewBox="0 0 16 16" className="bi bi-person-fill" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
  <path fillRule="evenodd" d="M3 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1H3zm5-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6z"/>
</svg>
                                </Link>
                            </span>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link" href="/">
                                <span className="glyphicon glyphicon-user profile" aria-hidden="true"></span>
                            </a>
                        </li>
                        <li className="nav-item pl-3 pb-3 pl-lg-0 pb-lg-0">
                            <SignOut user={this.props.user} />
                        </li>
                    </ul>
                }
                { !this.props.user &&
                    <ul className="nav navbar-nav">
                        <li className="nav-item pl-3 pb-3 pl-lg-0 pb-lg-0">
                            <button type="button" className="btn btn-default btn-sm my-2 my-sm-0" data-toggle="modal" data-target="#log-in-modal" data-js="btn-log-in">Sign In</button>
                        </li>
                    </ul>
                }
            </div>
        </nav>
      );
    }
  }

  export default NavBar;
  

