import React from 'react';
import './PlayerCard.scss';

function PlayerCard(props) {
    let overallChampionships = 0;
    let cashChampionships = 0;
    let pointChampionships = 0;
    
    if (props.player.championships) {
        props.player.championships.forEach((championship) => {
            switch (championship.type) {
                case 'OVERALL': ++overallChampionships; break;
                case 'POINT': ++pointChampionships; break;
                case 'CASH': ++cashChampionships; break;
                default:
            }
        })
    } 

    return (
        <div className="panel mb-3 player-card">
            <div className="row">
                <div className="col">
                    <h6 className="title">{props.player.name}</h6>
                    <hr />
                </div>
            </div>
            <div className="row">
                <div className="col-12"><label className="text-bold">Name:</label> { props.player.firstName } { props.player.lastName }</div>
                { props.player.memberSince &&
                    <div className="col-12"><label className="text-bold">Member Since:</label> { props.player.contactEmail || '-' }</div>
                }
                <div className="col-12"><label className="text-bold">Championships:</label></div>
            </div>
            <div className="row">
                <div className="col">
                    <div className="championship p-1">
                        <span className="text-bold">Overall</span>
                        <span className="float-right badge badge-light">{overallChampionships}</span>
                    </div>
                </div>
                <div className="col">
                    <div className="championship p-1">
                    <span className="text-bold">Cash</span>
                        <div className="float-right badge badge-light">{cashChampionships}</div>
                    </div>
                </div>
                <div className="col">
                    <div className="championship p-1">
                        <span className="text-bold">Point</span>
                        <div className="float-right badge badge-light">{pointChampionships}</div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default PlayerCard;