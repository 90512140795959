import React from 'react';

function getOrdinalName(place) {
    const ordinalSuffixes = [ 'th', 'st', 'nd', 'rd', 'th', 'th', 'th', 'th', 'th', 'th'];
    let ordinalSuffix;

    switch (place % 100) {
        case 11:
        case 12:
        case 13:
            ordinalSuffix = 'th';
            break;
        default:
            ordinalSuffix = ordinalSuffixes[place % 10];
            break;
    }

    return `${place}${ordinalSuffix} Place`;
}

function Payout(props) {
    return (
        <div className="panel mb-3 game">
            <div className="row">
                <div className="col">
                    <h6 className="title">{getOrdinalName(props.place)}</h6>
                </div>
            </div>
            <div className="row">
                <div className="col-12 col-md-6">
                    <label>User</label>
                    <select name={`userId${props.place}`} className="form-control" onChange={props.handleInputChange} >
                        <option key="userNull" value="">-</option>
                        { props.users.map((user, index) => (
                            <option key={`user${index}`} value={user.name}>{user.name}</option>
                        ))}
                    </select>
                </div>
                <div className="col-12 col-md-6">
                    <label>Amount</label>
                    <select name={`amount${props.place}`} className="form-control" onChange={props.handleInputChange} >
                        <option key="amountNull" value="">-</option>
                        { props.payouts.map((payout, index) => (
                            <option key={`amount${index}`} value={payout.amount}>${payout.amount}</option>
                        ))}
                    </select>
                </div>
            </div>
        </div>
    );
}

export default Payout