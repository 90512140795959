import React from 'react';
import './PlayerSnapshot.scss';

class PlayerSnapshot extends React.Component {
    constructor(props) {
        super(props);
    
        // the user here will be shared by other pages/components throughout the app
        this.state = {
            loading: true,
            user: null,
            site: null
        };
    }

    componentDidMount() {
        // only make this call if we have a valid user.
        fetch("/api/user/stats/",
        {
            cache: "no-cache",
            credentials: 'same-origin', // This is required to send the cookies in the request.
            headers: {
                'Accept': 'application/json'
            }
        })
        .then(res => res.json())
        .then(
            (response) => {
                this.setState({
                    loading: false,
                    user: response.user,
                    site: response.site
                });
            },
            // Note: it's important to handle errors here
            // instead of a catch() block so that we don't swallow
            // exceptions from actual bugs in components.
            (error) => {
                this.setState({
                    loading: false,
                    user: null,
                    site: null
                });
            }
        );
    }

    render() {
        if (this.state.loading) {
            return (
                <div className="m-player-snapshot panel mb-3">
                </div>
            );
        } else if (this.state.user) {
            const { stats, playoffs } = this.state.user;
            return (
                <div className="m-player-snapshot panel mb-3">
                    
                    <div className="player-stats">
                        <h6 className="text-center">My Snapshot</h6>
                        <hr />
                        <dl className="stat-box">
                            <dt>Overall Rank</dt>
                            <dd>{stats.rank}</dd>
                            <dt>Season Points</dt>
                            <dd>{stats.points}</dd>
                            <dt>Places</dt>
                            <dd>{stats.numPlaces}</dd>
                            <dt>Winnings</dt>
                            <dd>${stats.cash}</dd>
                        </dl>
                    </div>

                    { playoffs ?
                        <div className="player-playoffs text-center">
                            <h6>Congratulations, you've made the playoffs!</h6>
                            <hr />
                            <div className="mb-3">Select below to make your picks as the {playoffs.seed} seed.</div>
                            <div>
                                <a className="btn btn-default" href="/submit-picks">Make Playoff Picks</a>
                            </div>
                        </div>
                    :
                        <div className="player-picks text-center">
                            { (this.state.site.currentWeek <= this.state.site.weekCount) &&
                            <div>
                                <h6>My Week {this.state.site.currentWeek} Picks</h6>
                                <hr />
                                { stats.submitDate ?
                                    <div>
                                        <svg width="32px" height="32px" viewBox="0 0 16 16" className="bi bi-patch-check-fll" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                            <path fillRule="evenodd" d="M10.067.87a2.89 2.89 0 0 0-4.134 0l-.622.638-.89-.011a2.89 2.89 0 0 0-2.924 2.924l.01.89-.636.622a2.89 2.89 0 0 0 0 4.134l.637.622-.011.89a2.89 2.89 0 0 0 2.924 2.924l.89-.01.622.636a2.89 2.89 0 0 0 4.134 0l.622-.637.89.011a2.89 2.89 0 0 0 2.924-2.924l-.01-.89.636-.622a2.89 2.89 0 0 0 0-4.134l-.637-.622.011-.89a2.89 2.89 0 0 0-2.924-2.924l-.89.01-.622-.636zm.287 5.984a.5.5 0 0 0-.708-.708L7 8.793 5.854 7.646a.5.5 0 1 0-.708.708l1.5 1.5a.5.5 0 0 0 .708 0l3-3z"/>
                                        </svg>
                                        &nbsp;&nbsp;
                                        <span className="text-bold">Received:</span> {stats.submitDate}
                                    </div>
                                :
                                    <div>
                                        <svg width="32px" height="32px" viewBox="0 0 16 16" className="bi bi-patch-exclamation-fll" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                            <path fillRule="evenodd" d="M10.067.87a2.89 2.89 0 0 0-4.134 0l-.622.638-.89-.011a2.89 2.89 0 0 0-2.924 2.924l.01.89-.636.622a2.89 2.89 0 0 0 0 4.134l.637.622-.011.89a2.89 2.89 0 0 0 2.924 2.924l.89-.01.622.636a2.89 2.89 0 0 0 4.134 0l.622-.637.89.011a2.89 2.89 0 0 0 2.924-2.924l-.01-.89.636-.622a2.89 2.89 0 0 0 0-4.134l-.637-.622.011-.89a2.89 2.89 0 0 0-2.924-2.924l-.89.01-.622-.636zM8 4a.905.905 0 0 0-.9.995l.35 3.507a.553.553 0 0 0 1.1 0l.35-3.507A.905.905 0 0 0 8 4zm.002 6a1 1 0 1 0 0 2 1 1 0 0 0 0-2z"/>
                                        </svg>
                                        &nbsp;&nbsp;
                                        <span className="text-bold">Not Received</span>
                                    </div>
                                }
                            </div>
                            }
                        </div>
                    }
                </div>
            );
        } else {
            return (
                <div className="m-player-snapshot panel mb-3">
                    <div className="inline-login text-center my-3">
                        <h5 className="text-center">Sign in to view your player snapshot</h5>
                        <button type="button" className="btn btn-default mt-3" data-toggle="modal" data-target="#log-in-modal">Sign In</button>
                    </div>
                </div>
            )
        }
    }
}

export default PlayerSnapshot;