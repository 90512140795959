import React, { useState } from 'react';

function ResetSeasonModal(props) {
    const messageInitial = "Are you sure you want to reset the season and setup for the new season?  Make sure that all the payouts have been applied through the Super Bowl before running this.  The changes made with this action cannot be undone.";
    const [ disabled, setDisabled ] = useState(false);
    const [ message, setMessage ] = useState(messageInitial);

    const handleReset = () => {
        setDisabled(false);
        setMessage(messageInitial);
    }

    const handleSubmit = (e) => {
        setDisabled(true);
        setMessage('Running...');
        // only make this call if we have a valid user.
        fetch("/api/admin/reset-season/",
        {
            method: "POST",
            cache: "no-cache",
            credentials: 'same-origin', // This is required to send the cookies in the request.
            headers: {
                'Accept': 'application/json'
            }
        })
        .then(res => res.json())
        .then(
            (response) => {
                if (response.status === 202) {
                    setMessage(<div>
                        Created New Season: {response.site.newSeason}<br />
                        Number Players Reset: {Object.keys(response.playerUpdates).length}<br />
                        Paid Flags Reset: {response.resetAllPaid}<br />
                        Reset Team Records: {response.resetTeamRecords}<br />
                        Reset Team Stats: {response.resetTeamStats}<br />
                        Set Overall Champion: {response.overallChampion.player}<br />
                        Set Cash Champions: {(response.cashChampions.map((champ) => (champ.player))).join(',')}<br />
                        Set Point Champions: {(response.pointChampions.map((champ) => (champ.player))).join(',')}<br />
                        Reset Season Settings: {response.resetSeasonSettings}<br />
                    </div>);
                } else {
                    setMessage(response.message);
                }
            },
            // Note: it's important to handle errors here
            // instead of a catch() block so that we don't swallow
            // exceptions from actual bugs in components.
            (error) => {
            }
        );

        e.preventDefault();
    };

    return (
        <div className="modal fade" id="reset-season-modal" tabIndex="-1" role="dialog" aria-labelledby="reset-season-label">
            <div className="modal-dialog" role="document">
                <div className="modal-content">
                <form name="log-in" onSubmit={handleSubmit}>
                <div className="modal-header">
                    <h4 className="modal-title">Reset Season</h4>
                    <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={handleReset}>
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div className="modal-body">
                    <div>
                        {message}
                    </div>
                </div>
                <div className="modal-footer">
                    <button type="button" className="btn btn-default" data-dismiss="modal" onClick={handleReset}>Take me back!</button>
                    <button type="submit" disabled={disabled} className={`btn btn-danger ${disabled ? 'disabled' : ''}`}>Yup, I'm Good!</button>
                </div>
            </form>
                </div>
            </div>
        </div>
    )
}

export default ResetSeasonModal;