import React, { useState, useEffect } from 'react';
import PlayoffSlot from '../components/PlayoffSlot';

function ManagePlayoffTeams() {
    const [ state, setState ] = useState({
        loading: true,
        teams: [],
        formValues: {},
        site: {},
        statusMessage: '',
    });
    

    const handleInputChange = (e) => {
        const formValues = { ...state.formValues };
        formValues[e.target.name] = e.target.value;
        setState({ ...state, formValues });
    };

    const buildPlayoffSlots = (conference) => {
        const { numPlayoffTeamsPerConference } = state.site;
        let slots = [];

        for (let i = 1; i <= numPlayoffTeamsPerConference; ++i) {
            slots.push(
                <div key={`${conference}${i}`} className="row">
                    <div className="col">
                        <PlayoffSlot teams={state.teams} slotId={i} conference={conference} selectedId={state.formValues[`${conference}${i}`]} handleInputChange={handleInputChange} />
                    </div>
                </div>
            );
        }

        return slots;
    }

    const handleSubmit = (e) => {
        let formData = new FormData();
        
        for (let property in state.formValues) {
            formData.append(property, state.formValues[property]);
        }

        // only make this call if we have a valid user.
        fetch('/api/admin/manage-playoff-teams/',
        {
            method: "POST",
            cache: "no-cache",
            credentials: 'same-origin', // This is required to send the cookies in the request.
            body: formData,
            headers: {
                'Accept': 'application/json'
            }
        })
        .then(res => res.json())
        .then(
            (response) => {
                let statusMessage;

                if (response.status === 202) {
                    statusMessage = <div>Successfully Updated Playoff Teams</div>;
                } else {
                    statusMessage = response.message;
                }

                setState({ ...state, statusMessage });
            },
            // Note: it's important to handle errors here
            // instead of a catch() block so that we don't swallow
            // exceptions from actual bugs in components.
            (error) => {
            }
        );

        e.preventDefault();
    }

    useEffect(() => {
        // handle initial load of data.
        if (state.loading) {
            let url = '/api/admin/manage-playoff-teams/';
            // only make this call if we have a valid user.
            fetch(url,
            {
                cache: "no-cache",
                credentials: 'same-origin', // This is required to send the cookies in the request.
                headers: {
                    'Accept': 'application/json'
                }
            })
            .then(res => res.json())
            .then(
                (response) => {
                    const formValues = {};
    
                    response.teams.forEach((team) => {
                        // is this a playoff team?  Set it as the selected value for our dropdown for this slot.
                        if (team.playoffSeed) {
                            formValues[`${team.conference.toLowerCase()}${team.playoffSeed}`] = team.id;
                        }
                    });
    
                    setState({ ...state, formValues, teams: response.teams, site: response.site, loading: false });
                },
                // Note: it's important to handle errors here
                // instead of a catch() block so that we don't swallow
                // exceptions from actual bugs in components.
                (error) => {
                }
            );
        }
    }, [state]);

    return (
        <div className="container mt-4">
            <div className="row">
                <div className="col">
                    <h4 className="title">Manage Playoff Teams</h4>
                <hr />
                </div>
            </div>
            <div className="row mb-3">
                <div className="col text-center">
                    { state.statusMessage }
                </div>
            </div>
            { !state.loading &&                
                <form onSubmit={handleSubmit}>
                    <div className="row">
                        <div className="col-12 col-md-6">
                            <div className="panel">
                                <h6 className="title">AFC Playoff Teams</h6>
                                { buildPlayoffSlots('afc') }
                            </div>
                        </div>
                        <div className="col-12 col-md-6">
                            <div className="panel">
                                <h6 className="title">NFC Playoff Teams</h6>
                                { buildPlayoffSlots('nfc') }
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col text-center mt-3">
                            <button type="submit" className="btn btn-primary">Set Playoff Teams</button>
                        </div>
                    </div>
                </form>
            }
        </div>
    );
}

export default ManagePlayoffTeams;