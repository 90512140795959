import React, { useState, useEffect } from 'react';
import Payout from '../components/Payout';
import WeekPaginator from '../../components/WeekPaginator';

function ManagePayouts(props) {
    const [ state, setState ] = useState({
        loading: true,
        site: {},
        users: [],
        payouts: []
    });
    const [ statusMessage, setStatusMessage ] = useState();
    const [ formValues, setFormValues ] = useState({});

    const getPayouts = (week) => {
        const webServices = [week ? `/api/league/payouts/?week=${week}` : '/api/league/payouts/', '/api/league/users/?filter=active'];
        const promiseURLs = webServices.map(url => fetch(url,
            {
                cache: "no-cache",
                headers: {
                    'Accept': 'application/json'
                }
            }).then(res => res.json()));
        
        Promise.all(promiseURLs)
            .then(responses => {
                setState( {
                    loading: false,
                    site: responses[0].site,
                    payouts: responses[0].payouts,
                    users: responses[1].userList,
                });
            });
    };

    const handleWeekSelected = (e) => {
        getPayouts(e.target.getAttribute('data-week'));
        e.preventDefault();
    };

    const handleInputChange = (e) => {
        const newFormValues = { ...formValues };
        newFormValues[e.target.name] = e.target.value;
        setFormValues(newFormValues);
    }

    const handleSubmit = (e) => {
        if (state.site.numPlaces) {
            const winners = [];
            for (let i = 1; i <= state.site.numPlaces; ++i) {
                if (formValues[`userId${i}`] && formValues[`amount${i}`]) {
                    winners.push({
                        id: formValues[`userId${i}`],
                        prize: Number(formValues[`amount${i}`]),
                    });
                }
            }

            // only make this call if we have a valid user.
            fetch('/api/admin/post-winners/',
            {
                method: "POST",
                cache: "no-cache",
                credentials: 'same-origin', // This is required to send the cookies in the request.
                body: JSON.stringify({
                    week: state.site.requestedWeek,
                    winners
                }),
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                }
            })
            .then(res => res.json())
            .then(
                (response) => {
                    setStatusMessage(response.message);
                },
                // Note: it's important to handle errors here
                // instead of a catch() block so that we don't swallow
                // exceptions from actual bugs in components.
                (error) => {
                    setStatusMessage('Unknown Error');
                }
            );
        }
    
        e.preventDefault();
    }
    
    useEffect(() => {
        getPayouts();
    }, []);

    return (
    <div className="container mt-4">
        <div className="row">
            <div className="col">
                <h4 className="title">Manage Payouts</h4>
                <hr />
            </div>
        </div>
        <div className="row mb-3">
            <div className="col">
                <WeekPaginator
                    weekCount={state.site.weekCount}
                    requestedWeek={state.site.requestedWeek}
                    showPlayoffs={true}
                    handleClick={handleWeekSelected}>
                </WeekPaginator>
            </div>
        </div>
        <div className="row mb-3">
            <div className="col text-center">
                { statusMessage }
            </div>
        </div>
        { !state.loading &&
            <form onSubmit={handleSubmit}>
                { 
                    state.payouts.season.map((payout, index) => (
                        <Payout key={`payout${index}`} handleInputChange={handleInputChange} place={index + 1} users={state.users} payouts={(state.site.requestedWeek >= state.site.playoffStartWeek) ? state.payouts.playoff : state.payouts.season} />
                    ))
                }
                <div className="row">
                    <div className="col text-center">
                        <button type="submit" className="btn btn-primary">Submit</button>
                    </div>
                </div>
            </form>
        }
    </div>
    );
}


export default ManagePayouts;