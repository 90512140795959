import React from 'react';
import './PlayoffBracket.scss';
import PlayoffMatchup from './PlayoffMatchup';

function PlayoffBracket(props) {
    return (
        <div className={`playoff-bracket`}>
            <div className="headers">
                <h6>{props.conference.toUpperCase()} Wild Card</h6>
                <h6>{props.conference.toUpperCase()} Divisional</h6>
                <h6>{props.conference.toUpperCase()} Championship</h6>
            </div>
            <div className="bracket">
                <div className="round">
                    { props.schedule.wildcard[props.conference].map((matchup, index) => (
                        <PlayoffMatchup key={`${props.conference}-wildcard-${index}`} home={matchup.home} away={matchup.away} conference={props.conference}/>
                    ))
                    }
                </div>
                <div className="round">
                    { props.schedule.divisional[props.conference].map((matchup, index) => (
                        <PlayoffMatchup key={`${props.conference}-divisional-${index}`} home={matchup.home} away={matchup.away} conference={props.conference}/>
                    ))
                    }
                </div>
                <div className="round">
                    { props.schedule.conference[props.conference] ? 
                        <PlayoffMatchup home={props.schedule.conference[props.conference].home} away={props.schedule.conference[props.conference].away} conference={props.conference}/>
                        :
                        <PlayoffMatchup conference={props.conference}/>
                    }
                </div>
            </div>
        </div>
    )
}

export default PlayoffBracket;