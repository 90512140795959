import React from 'react';
import MailForm from '../components/MailForm';

class SendMail extends React.Component {
    constructor(props) {
        super(props);
        
        this.state = {
            players: null,
        };
    }

    componentDidMount() {
        fetch("/api/admin/manage-players/",
        {
            cache: "no-cache",
            credentials: 'same-origin', // This is required to send the cookies in the request.
            headers: {
                'Accept': 'application/json'
            }
        })
        .then(res => res.json())
        .then(
            (response) => {
                this.setState({
                    players: response.players,
                });
            },
            // Note: it's important to handle errors here
            // instead of a catch() block so that we don't swallow
            // exceptions from actual bugs in components.
            (error) => {
            }
        );
    }

    render() {
        return (
            <div className="container mt-4">
                <div className="row">
                    <div className="col">
                        <h4 className="title">Send Mail</h4>
                        <hr />
                    </div>
                </div>
                <div className="row mb-3">
                    <div className="col">
                        <MailForm players={this.state.players}></MailForm>
                    </div>
                </div>
            </div>
        );
    }
}
  

export default SendMail;