import React from 'react';

function PlayerCard(props) {
    const handleClick = (e) => {
        switch (e.currentTarget.name) {
            case 'btn-active': 
                props.handlePlayerUpdate(props.player.name, !props.player.isActive, props.player.isPaid);
                break;
            case 'btn-paid':
                props.handlePlayerUpdate(props.player.name, props.player.isActive, !props.player.isPaid);
                break;
            default:
        }

        e.stopPropagation();
        e.preventDefault();
    }

    return (
        <div className="panel mb-3 player-card">
            <div className="row">
                <div className="col">
                    <h6 className="title">{props.player.name}</h6>
                    <hr />
                </div>
            </div>
            <div className="row">
                <div className="col-12"><span className="text-bold">Name:</span> { props.player.firstName } { props.player.lastName }</div>
                <div className="col-12"><span className="text-bold">Email:</span> { props.player.contactEmail || '-' }</div>
                <div className="col-12"><span className="text-bold">Paypal:</span> { props.player.paypalEmail || '-' }</div>
                <div className="col-6"><span className="text-bold">Active:</span> { props.player.isActive ? 'Yes' : 'No' }</div>
                <div className="col-6"><span className="text-bold">Is Paid?:</span> { props.player.isPaid ? 'Yes' : 'No' }</div>
            </div>
            <div className="row">
                <div className="col actions-container">
                    <hr className="mt-2"/>
                    <button name="btn-active" type="button" className="btn btn-default btn-sm mx-1" onClick={handleClick}>{ props.player.isActive ? 'Deactivate' : 'Activate'}</button>
                    <button name="btn-paid" type="button" className="btn btn-default btn-sm mx-1" onClick={handleClick}>{ props.player.isPaid ? 'Clear Paid' : 'Mark Paid'}</button>
                    <button name="btn-delete" type="button" className="float-right btn btn-danger btn-sm mx-1">Delete</button>
                </div>
            </div>
        </div>
    );
}

export default PlayerCard;