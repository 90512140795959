
import React from 'react';
import PlayerCard from '../components/PlayerCard';

class Players extends React.Component {
    constructor(props) {
        super(props);
        this.refreshPlayers = this.refreshPlayers.bind(this);
        this.filterPlayers = this.filterPlayers.bind(this);
        this.handleUpdateFilter = this.handleUpdateFilter.bind(this);

        this.state = {
            originalPlayers: null,
            players : null,
            filter: ''
        };
    }

    filterPlayers(players, filter) {
        return players.filter((player) => {
            return (!filter ||
                    (player.name.toLowerCase().indexOf(filter.toLowerCase()) >= 0) ||
                    (player.lastName.toLowerCase().indexOf(filter.toLowerCase()) >= 0));
        })
    }

    handleUpdateFilter(e) {
        const filter = e.currentTarget.value;
        this.setState({
            filter,
            players: this.filterPlayers([...this.state.originalPlayers], filter)
        });
    }

    refreshPlayers() {
        fetch("/api/league/users/?filter=active",
        {
            cache: "no-cache",
            credentials: 'same-origin', // This is required to send the cookies in the request.
            headers: {
                'Accept': 'application/json'
            }
        })
        .then(res => res.json())
        .then(
            (response) => {
                const currentFilter = this.state.filter;
                this.setState({
                    originalPlayers: [...response.userList],
                    players: this.filterPlayers(response.userList, currentFilter)
                });
            },
            // Note: it's important to handle errors here
            // instead of a catch() block so that we don't swallow
            // exceptions from actual bugs in components.
            (error) => {
            }
        );
    }

    componentDidMount() {
        this.refreshPlayers()
    }

    render() {
        return (
            <div className="container mt-4">
                <div className="row">
                    <div className="col">
                        <h4 className="title">Players</h4>
                        <hr />
                    </div>
                </div>
                <div className="row mb-3">
                    <div className="col-12">
                        <div className="input-group">
                            <div className="input-group-prepend">
                                <span className="input-group-text" id="basic-addon1">
                                    <svg width="1em" height="1em" viewBox="0 0 16 16" className="bi bi-search" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                        <path fillRule="evenodd" d="M10.442 10.442a1 1 0 0 1 1.415 0l3.85 3.85a1 1 0 0 1-1.414 1.415l-3.85-3.85a1 1 0 0 1 0-1.415z"/>
                                        <path fillRule="evenodd" d="M6.5 12a5.5 5.5 0 1 0 0-11 5.5 5.5 0 0 0 0 11zM13 6.5a6.5 6.5 0 1 1-13 0 6.5 6.5 0 0 1 13 0z"/>
                                    </svg>
                                </span>
                            </div>
                            <input type="text" name="filter" className="form-control" placeholder="Search/Filter Players" aria-label="Search/Filter" aria-describedby="basic-addon1" value={this.state.filter} onChange={this.handleUpdateFilter} />
                        </div>
                    </div>
                </div>
                { this.state.players && 
                    <div className="row">
                        { this.state.players.map((player, index) => (
                            <div key={`player${index}`} className="col-12 col-md-6 col-lg-4">
                                <PlayerCard player={player}></PlayerCard>
                            </div>
                        ))
                        }
                    </div>
                }
            </div>
        );
    }
}

export default Players;