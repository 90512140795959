import React from 'react';

class PickConfirmationModal extends React.Component {
    render() {
        return (
            <div className="modal fade" id="pick-confirmation-modal" tabIndex="-1" role="dialog" aria-labelledby="pick-confirmation-label">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h4 className="modal-title">{this.props.title}</h4>
                            <button type="button" className="close" data-dismiss="modal" onClick={this.handleChangeView} aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            { this.props.message }
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-default" data-dismiss="modal">OK</button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default PickConfirmationModal;