import React from 'react';
import './Payouts.scss';

function SeasonPayouts(props) {
    if (props.list) {
        const list = props.list;

        const listItems = list.map((item, index) => { 
            return (
                <tr key={`seasonPayout${index}`}>
                    <td>{item.place}</td>
                    <td className="text-right">${item.amount}</td>
                </tr>
            );
            }
        );
            
        return (
            <tbody>{listItems}</tbody>
        );
    }
    return <tbody></tbody>;
}
    

function PlayoffPayouts(props) {
    if (props.list) {
        const list = props.list;

        const listItems = list.map((item, index) => {
            return (
            <tr key={`playoffPayout${index}`}>
                <td>{item.round}</td>
                <td className="text-right">${item.amount}</td>
            </tr>
            );
            }
        );
            
        return (
            <tbody>{listItems}</tbody>
        );
    }
    return <tbody></tbody>;
}

class Payouts extends React.Component {
    constructor(props) {
        super(props);

        this.handleClick = this.handleClick.bind(this);
    
        // the user here will be shared by other pages/components throughout the app
        this.state = {
            loading: true,
            currentSelection: 'season',
            currentSeason: '-',
            seasonPayouts: null,
            playoffPayouts: null
        };
    }

    handleClick(e) {
        this.setState( {
            currentSelection: e.target.getAttribute('data-target')
        });
    }

    componentDidMount() {
        // only make this call if we have a valid user.
        fetch("/api/league/payouts/",
        {
            cache: "no-cache",
            headers: {
                'Accept': 'application/json'
            }
        })
        .then(res => res.json())
        .then(
            (response) => {
                this.setState({
                    loading: false,
                    currentSeason: response.site.currentSeason,
                    seasonPayouts: response.payouts.season,
                    playoffPayouts: response.payouts.playoff
                });
            },
            // Note: it's important to handle errors here
            // instead of a catch() block so that we don't swallow
            // exceptions from actual bugs in components.
            (error) => {
                this.setState({
                    loading: false
                });
            }
        );
    }

    render() {
        return (
            <div className="m-payouts panel mb-3" data-js="payouts">
                <h6 className="title text-center">{this.state.currentSeason} Prizes</h6>
                <hr />
                <div onClick={this.handleClick} className="btn-group btn-group-sm text-center mb-3" role="group" aria-label="Choose Payout Filter">
                    <button type="button" className={`btn btn-default ${(this.state.currentSelection === 'season') ? 'active' : ''}`} data-target="season">Regular Season</button>
                    <button type="button" className={`btn btn-default ${(this.state.currentSelection === 'playoff') ? 'active' : ''}`} data-target="playoff">Playoffs</button>
                </div>
                <table className={`table table-sm ${this.state.currentSelection === 'season' ? '' : 'd-none'}`} data-js="filter" data-id="season">
                    <thead className="thead-inverse">
                        <tr>
                            <th>Place</th>
                            <th className="text-right">Prize</th>
                        </tr>
                    </thead>
                    <SeasonPayouts list={this.state.seasonPayouts}></SeasonPayouts>
                </table>
                <table className={`table table-sm ${this.state.currentSelection === 'playoff' ? '' : 'd-none'}`} data-js="filter" data-id="playoff">
                    <thead className="thead-inverse">
                        <tr>
                            <th>Round</th>
                            <th className="text-right">Prize</th>
                        </tr>
                    </thead>
                    <PlayoffPayouts list={this.state.playoffPayouts}></PlayoffPayouts>
                </table>
            </div>
                
        );
    }
}

export default Payouts;