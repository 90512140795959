import React from 'react';
import './GameSubmitForm.scss';
import MatchupContainer from './MatchupContainer';
import PickConfirmationModal from './PickConfirmationModal';
import Tiebreaker from './Tiebreaker';

function Confidences(props) {
    const numGames = props.schedule.length;    
    const confidences = props.schedule.map((game, index) => (
        <div key={`confidence${index}`} className="confidence p-2 text-center text-bold">
            { game.info.deadlinePassed ?
                <svg width="1em" height="1em" viewBox="0 0 16 16" className="bi bi-lock-fill" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                    <path d="M2.5 9a2 2 0 0 1 2-2h7a2 2 0 0 1 2 2v5a2 2 0 0 1-2 2h-7a2 2 0 0 1-2-2V9z"/>
                    <path fillRule="evenodd" d="M4.5 4a3.5 3.5 0 1 1 7 0v3h-1V4a2.5 2.5 0 0 0-5 0v3h-1V4z"/>
                </svg>
            :
                <span>{props.isPlayoffs ? (index + 1) : (numGames - index)}</span>
            }
        </div>
    ));
    return confidences;
}

class GameSubmitForm extends React.Component {
    constructor(props) {
        super(props);

        this.handleSubmit = this.handleSubmit.bind(this);

        this.state = {
            confirmationModal: {
                title: '',
                message: ''
            }
        }
    }

    handleSubmit(e) {
        // don't allow submit if the final deadline has passed.
        if (!this.props.site.finalDeadlinePassed) {
            const formData = new FormData();
            const picks = document.querySelectorAll('[data-js="matchup"]');

            formData.append('userId', this.props.user.name);

            if (this.props.isPlayoffs) {
                const playoffPicks = [];
                picks.forEach((pick, index) => {
                    playoffPicks.push(pick.getAttribute('data-id'));
                });

                formData.append(`picks`, playoffPicks.join('|'));

                // get the current user, if valid.
                fetch("/api/playoffs/picks/", { method: 'POST', cache: "no-cache", credentials: 'same-origin', body: formData, headers: { 'Accept': 'application/json' }})
                .then(res => res.json())
                .then((response) => {
                    if (response.status === 200) {
                        this.setState({
                            confirmationModal: {
                                title: 'Playoff Picks Received',
                                message: response.message
                            }
                        });

                        const lockedSchedule = [...this.props.schedule];
                        lockedSchedule.forEach((game, index) => {
                            game.info.deadlinePassed = true;
                        });

                        this.props.updateSchedule(lockedSchedule);
                    } else {
                        this.setState({
                            confirmationModal: {
                                title: 'Playoff Pick Submission Failed',
                                message: response.message
                            }
                        });
                    }

                    const modal = document.getElementById('btn-confirmation-modal');
                    modal.click();
                },
                    // Note: it's important to handle errors here
                    // instead of a catch() block so that we don't swallow
                    // exceptions from actual bugs in components.
                    (error) => {
                        console.log('error=' + JSON.stringify(error));
                    }
                )
            } else {
                const gameCount = this.props.site.gameCount;
                const tiebreaker = document.querySelector('[data-js="tiebreaker"]');

                formData.append('gameCount', this.props.site.gameCount);
                formData.append('week', this.props.site.requestedWeek);
                formData.append('tiebreaker', tiebreaker.value);

                picks.forEach((pick, index) => {
                    formData.append(`pick${gameCount - index}`, pick.getAttribute('data-pick') ? pick.getAttribute('data-pick') : '');
                });
                
                // get the current user, if valid.
                fetch("/api/user/picks/",
                    {
                        method: 'POST',
                        cache: "no-cache",
                        credentials: 'same-origin', // This is required to send the cookies in the request.
                        body: formData,
                        headers: {
                            'Accept': 'application/json'
                        }
                    })
                .then(res => res.json())
                .then((response) => {
                    if (response.status === 200) {
                        this.setState({
                            confirmationModal: {
                                title: 'Pick Submission Successful',
                                message: response.message
                            }
                        });
                    } else {
                        this.setState({
                            confirmationModal: {
                                title: 'Pick Submission Failed',
                                message: response.message
                            }
                        });
                    }

                    const modal = document.getElementById('btn-confirmation-modal');
                    modal.click();
                },
                    // Note: it's important to handle errors here
                    // instead of a catch() block so that we don't swallow
                    // exceptions from actual bugs in components.
                    (error) => {
                        console.log('error=' + JSON.stringify(error));
                    }
                )
            }
        }

        e.preventDefault();
    }

    render() {
        const site = this.props.site;
        const user = this.props.user;
        const schedule = this.props.schedule;
        
        return (
            <div className="m-game-submit-form">
                <button type="button" id="btn-confirmation-modal" className="d-none" data-toggle="modal" data-target="#pick-confirmation-modal"></button>
                <PickConfirmationModal title={this.state.confirmationModal.title} message={this.state.confirmationModal.message}></PickConfirmationModal>
                {/* Don't show ability to submit picks if the user is not logged in */}
                { user ?
                    <form onSubmit={this.handleSubmit}>
                        <div className="matchup-container bg-dark">
                            <div className="confidences">
                                <Confidences schedule={schedule} isPlayoffs={this.props.isPlayoffs}></Confidences>
                            </div>
                            <MatchupContainer isPlayoffs={this.props.isPlayoffs} playoffSeed={this.props.site.playoffSeed} schedule={schedule} updateSchedule={this.props.updateSchedule}></MatchupContainer>
                        </div>
                        <div className="button-container text-center py-3">
                            <button type="submit" className={`btn btn-primary text-center ${site.finalDeadlinePassed ? 'disabled' : ''}`}>
                                Submit Picks
                            </button>
                            { !this.props.isPlayoffs &&
                                <span className="d-md-none">
                                    &nbsp;
                                    &nbsp;
                                    <Tiebreaker></Tiebreaker>
                                </span>
                            }
                        </div>
                    </form>
                :
                    <div className="text-center my-3">
                        You must be signed in to submit picks.
                    </div>
                }
            </div>
        );
    }
}

export default GameSubmitForm;