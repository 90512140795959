import React from 'react';

class SignInForm extends React.Component {
    constructor(props) {
        super(props);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleChangeView = this.handleChangeView.bind(this);
        this.handleModalClosed = this.handleModalClosed.bind(this);

        this.state = {
            userId: '',
            password: ''
        };
    }

    componentDidMount() {
        this.setState({
            userId: '',
            password: ''
        });
    }
    
    handleModalClosed(e) {
        this.setState({
            userId: '',
            password: ''
        });
    }

    handleChangeView(e) {
        this.props.changeView(e.currentTarget.getAttribute('data-view'));

        e.preventDefault();
        e.stopPropagation();
    }

    handleInputChange(e) {
        this.setState({
          [e.currentTarget.name]: e.currentTarget.value
        });
      }

    handleSubmit(e) {
        let formData = new FormData();

        formData.append('userid', this.state.userId);
        formData.append('password', this.state.password);

        // get the current user, if valid.
        fetch("/api/authentication/signin/",
            {
                method: "POST",
                cache: "no-cache",
                credentials: 'same-origin', // This is required to send the cookies in the request.
                body: formData,
                headers: {
                    'Accept': 'application/json'
                }
            })
        .then(res => res.json())
        .then(
            (response) => {
                if (response.status === 200) {
                    // reload the entire page to get the updated user state if successful
                    window.location.reload(true);
                } else {
                    this.setState({
                        errorMessage: response.message
                    })
                }
            },
            // Note: it's important to handle errors here
            // instead of a catch() block so that we don't swallow
            // exceptions from actual bugs in components.
            (error) => {
                console.log('error=' + JSON.stringify(error));
            }
        )

        e.preventDefault();
    }

    render() {
        return (
            <form name="log-in" onSubmit={this.handleSubmit}>
                <div className="modal-header">
                    <h4 className="modal-title">Sign In</h4>
                    <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={this.handleModalClosed}>
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div className="modal-body">
                    <div className="form-group error" data-js="error">{this.state.errorMessage}</div>
                    <div className="form-group">
                        <label htmlFor="userid">Username</label>
                        <input type="text" autoComplete="username" name="userId" id="userid" className="form-control" value={this.state.userId} onChange={this.handleInputChange} />
                    </div>
                    <div className="form-group">
                        <label htmlFor="password">Password</label>
                        <input type="password" autoComplete="current-password" name="password" id="password" className="form-control" value={this.state.password} onChange={this.handleInputChange} />
                    </div>
                    <div className="form-group">
                        <a href="/" data-view="forgotpassword" onClick={this.handleChangeView}>Forgot Password?</a>
                    </div>
                </div>
                <div className="modal-footer">
                    <button type="button" className="btn btn-default" data-dismiss="modal">Cancel</button>
                    <button type="submit" className="btn btn-primary">Sign In</button>
                </div>
            </form>
        );
    }
}

export default SignInForm;