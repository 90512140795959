import React from 'react';
import './App.scss';
import NavBar from './components/NavBar';
import Home from './pages/Home';
import NflSchedule from './pages/NflSchedule';
import NflStandings from './pages/NflStandings';
import Leaderboards from './pages/Leaderboards';
import SubmitPicks from './pages/SubmitPicks';
import Results from './pages/Results';
import UserProfile from './pages/UserProfile';
import SignInModal from './components/SignInModal';
import ManagePlayers from './admin/pages/ManagePlayers';
import ManageSchedule from './admin/pages/ManageSchedule';
import ManagePlayoffTeams from './admin/pages/ManagePlayoffTeams';
import ManagePayouts from './admin/pages/ManagePayouts';
import LeagueSettings from './admin/pages/LeagueSettings';
import SendMail from './admin/pages/SendMail';
import Players from './pages/Players';
import Rules from './pages/Rules';

import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";


class App extends React.Component {
  constructor(props) {
    super(props);

    // the user here will be shared by other pages/components throughout the app
    this.setUser = this.setUser.bind(this);
    this.state = { user: null};
  }

  // this allow other components to set the user (for example after login or logout)
  setUser(user) {
    this.setState({ user });
  }

  componentDidMount() {
    // get the current user, if valid.
    fetch("/api/user/detail/",
        {
          cache: "no-cache",
          credentials: 'same-origin', // This is required to send the cookies in the request.
        })
      .then(res => res.json())
      .then(
        (response) => {
          this.setState({
            user: response.user
          });
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {
          this.setState({
            user: null
          });
        }
      )
  }

  render() {
    return (
      <Router>
        <div>
          <NavBar
            user={this.state.user}
            setUser={this.setUser}
          ></NavBar>
          <SignInModal></SignInModal>

          {/* A <Switch> looks through its children <Route>s and
              renders the first one that matches the current URL. */}
            <Switch>
                <Route path="/submit-picks">
                    <SubmitPicks />
                </Route>
                <Route path="/results">
                    <Results />
                </Route>
                <Route path="/leaderboards">
                    <Leaderboards />
                </Route>
                <Route path="/nfl-schedule">
                    <NflSchedule />
                </Route>
                <Route path="/nfl-standings">
                    <NflStandings />
                </Route>
                <Route path="/players">
                    <Players />
                </Route>
                <Route path="/rules">
                    <Rules />
                </Route>
                <Route path="/profile">
                    <UserProfile />
                </Route>
                <Route path="/manage-players">
                    <ManagePlayers></ManagePlayers>
                </Route>
                <Route path="/manage-schedule">
                    <ManageSchedule></ManageSchedule>
                </Route>
                <Route path="/league-settings">
                    <LeagueSettings></LeagueSettings>
                </Route>
                <Route path="/send-mail">
                    <SendMail></SendMail>
                </Route>
                <Route path="/manage-playoff-teams">
                    <ManagePlayoffTeams />
                </Route>
                <Route path="/post-winners">
                    <ManagePayouts />
                </Route>
                <Route path="/">
                    <Home user={this.state.user}/>
                </Route>
            </Switch>
        </div>
      </Router>
    );
  }
}

export default App;
