
import React from 'react';
import UserProfileForm from '../components/UserProfileForm';

class UserProfile extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            user : null,
            userList: null
        };
    }

    componentDidMount() {
        const webServices = ['/api/league/users/?filter=active', '/api/user/detail'];
        const promiseURLs = webServices.map(url => fetch(url,
            {
                credentials: 'same-origin', // This is required to send the cookies in the request.
                cache: "no-cache",
                headers: {
                    'Accept': 'application/json'
                }
            }).then(res => res.json()));
        
        Promise.all(promiseURLs)
            .then(responses => {
                this.setState( {
                    loading: false,
                    userList: responses[0].userList,
                    user: responses[1].user
                });
            });
    }

    render() {
        return (
            <div className="container mt-4">
                <div className="row">
                    <div className="col">
                        <h4 className="title">{this.state.user ? this.state.user.name : 'User'} Profile</h4>
                    </div>
                </div>
                { this.state.user ?
                    <UserProfileForm user={this.state.user} userList={this.state.userList}></UserProfileForm>
                :
                    <div>You must be logged in to view this page.</div>
                }
            </div>
        );
    }
}

export default UserProfile;