
import React from 'react';

class Rules extends React.Component {
    render() {
        return (
            <div className="container mt-4">
                <div className="row">
                    <div className="col">
                        <h4 className="title">Rules</h4>
                        <hr />
                    </div>
                </div>
                <div className="row mb-3">
                    <div className="col-12">
                        <div className="panel mb-3">
                            <h6 className="title">Overview</h6>
                            <hr />
                            <div>
                                <p>Salsero Football is a straight NFL confidence pool.  Players attempt to pick the winners
                                each week against the posted NFL schedule by ranking their picks from most confident to least confident.
                                After each week, winners are determined and things reset for the next week.  Weekly totals are kept where at the end
                                of the season, the top 12 players advance to the playoffs.</p>
                                <p>The league is not open to the public and is strictly by invite and/or referral only.</p>
                            </div>
                        </div>
                        <div className="panel mb-3">
                            <h6 className="title">Making Picks</h6>
                            <hr />
                            <div>
                                <p>Picks are always made online via the 'My Picks' page.  There you can see the posted schedules for each week.
                                Picks are available at a minimum 2 weeks in advance.  Since the NFL implements a "flex schedule" option, which gives them
                                the right to modify the day/times of their games based on the popular games of the week, it is difficult to
                                post a schedule any further than 2 weeks in advance.</p>
                                <p>Once a schedule is available, full or partial picks can be made for that week.  Each game is labeled with a deadline, which
                                signifies when the pick is due for that week.  Any game that is past it's deadline is marked with a 'lock'
                                icon which prevents you from modifying the pick or confidence for that game.</p>
                                <p>It is important to note here that there are multiple deadlines for a week.  The general policy for setting deadline is this...
                                </p>
                                <ul>
                                    <li>Any game that is before Sunday for a given week, the deadline is the kickoff time.</li>
                                    <li>Any game that is on Sunday or after, the deadline is usually the kickoff for the FIRST Sunday game.</li>
                                    <li>There are always exceptions and/or special cases that need to be considered, most notably games played out of the country.</li>
                                </ul>
                                <p>There are three main steps to making picks, a) select your team, b) chooose your confidence and c) set your tiebreaker.</p> 
                                <p>To select your team for a given game, simply click/select that team's name/icon
                                on the game submit form.</p>
                                <p>Choosing your confidence can be achieved by dragging and/or moving games up/down based on the confidence labels.  The important thing to understand with this part is
                                that confidences go from "most confident" to "least confident" where the higher the number means the higher the confidence.  You can only assign one pick per confidence.</p>
                                <p>The tiebreaker is always tied to the last game of the week.  It is simply how may points you think will be scored between BOTH teams.  This is used to break
                                ties if you are in the money for the week. The tiebreaker can be made at any time during the week as long as it's before the final deadline for the week.  Once the final deadline has passed,
                                the tiebreaker cannot be modified.</p>
                            </div>
                        </div>
                        <div className="panel mb-3">
                            <h6 className="title">Late, Missing, and Incomplete Picks</h6>
                            <hr />
                            <div>
                                <p>Picks for a given week are due by the deadline posted next to each game and can only be made online.  We do not accept picks any other way.
                                This is done for many different reasons, with the most important one being fairness.  It's your responsibility to pay attention
                                to the deadlines and get your picks in on time.</p>
                                <p>Incomplete picks can occur when you submit less than 50% of your picks for a given week.  If this happens, your picks are considered incomplete.
                                Your picks will be marked as such on the results page for a given week once the final deadline has passed for a week.</p>
                                <p>With all late, missing and incomplete picks, the penalty is 1 point less than the player with the lowest score for the week among the valid picks.
                                This allows you to have a mulligan or two for a given season without burying you for the season if you are in the playoff hunt.</p>
                            </div>
                        </div>
                        <div className="panel mb-3">
                            <h6 className="title">Determining Winners</h6>
                            <hr />
                            <div>
                                <p>Winners are determined weekly upon the completion of each week's games.  Weekly prizes are available to the top X players each week, 
                                where X is the number of weekly prizes available.  This is set once at the start of each season based on the number of players in a given season.</p>
                                <p>Total points for a given week are determined by the number of correct picks made.  The confidence for each correct pick is added together to come up with a total for each player.
                                If a particular game ends in a tie, it is treated the same as a loss, meaning everyone loses.</p>
                                <p>The tiebreaker factors in only if a particular user is "in the money", meaning it is used to break ties for a given weekly prize.  If multiple players
                                have the same tiebreaker and are in the money, they will share the weekly prizes.  For example, if we are awarding prizes for 1st, 2nd and 3rd, if there is a tie for 2nd place,
                                the top player will take the full prize for 1st and the players tied for 2nd place will split the prize for 2nd AND 3rd place evenly.  In that scenario no additional payout will
                                be made for 3rd place.</p>
                            </div>
                        </div>
                        <div className="panel mb-3">
                            <h6 className="title">Entry Fees/Refunds</h6>
                            <hr />
                            <div>
                                <p>Entry fee is required to be received before the kickoff of the 1st game of the season.  It's important that all entry fees are received in a timely manner
                                as all money received is given out as winnings.  If your entry fee is not received by the 1st game of the season, your accoount will be suspended until payment is received.  
                                You are not eligible for weekly payouts while your account is suspended.</p>
                                <p>Refunds are not given for any reason.</p>
                            </div>
                        </div>
                        <div className="panel mb-3">
                            <h6 className="title">Playoffs</h6>
                            <hr />
                            <div>
                                <p>Playoffs are available to the top 14 players based on overall points earned for the season.  The number of players that make the playoffs is equal to the number of teams in the actual NFL playoffs, currently at 14.
                                Should there be ties between one or more players, the following tiebreakers will be applied in the following order to break them:</p>
                                <p>
                                    <div><span class="text-bold">1. </span>Season Cash Total</div>
                                    <div><span class="text-bold">2. </span>Season High Weekly Point Total</div>
                                </p>
                                <p>For the playoffs instead of picking weekly winners, you are assigned a team for the entire playoffs.  When that team is eliminated, so are you.  Team picks are made based on your overall position in the standings.
                                If you finished 1st, you get the 1st pick among all the NFL playoff teams.  If you finished last, you get the last available team after everyone has picked.</p>
                                <p>Since the window is limited to select your playoff team, it is
                                up to you to get your playoff team selected in a timely manner to allow everyone to get their pick in.  If you for some reason do not get your playoff pick in before the start of the playoffs, you will
                                receive whatever pick is left over after everyone has picked.</p>
                                <p>The playoff pot is determined at the start of the season and posted on the website.  It varies from year to year and is directly dependent on how many players play in a given year.  Overall, the farther your team goes
                                in the playoffs, the more money you can win.  Every player in the playoffs is guaranteed a minimum amount just for making the playoffs.
                                </p>
                                <p>The following playoff prizes are awarded:
                                <ul>
                                    <li>Wild Card Losers x 6</li>
                                    <li>Divisional Losers x 4</li>
                                    <li>Conference Championship Losers x 2</li>
                                    <li>Super Bowl Runner-up x 1</li>
                                    <li>Super Bowl Winner x 1</li>
                                </ul></p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Rules;

