import React from 'react';
import './WallOfChampions.scss';

function ChampionList(props) {
    if (props.list) {
        const list = props.list;
        const listItems = list.map((item, index) => { 
            if (props.type === 'overall') {
                return (
                    <tr key={`overall${index}`}>
                        <td>{item.year}</td>
                        <td className="text-right">{item.userId}</td>
                    </tr>
                );
            } else {
                return (
                    <tr key={`${props.type}${index}`}>
                        <td>{item.year}</td>
                        <td>{item.userId}</td>
                        <td>{item.value}</td>
                    </tr>
                );
            }
        });

        return (
            <tbody>{listItems}</tbody>
        );
    }
    return <tbody></tbody>;
}

class WallOfChampions extends React.Component {
    constructor(props) {
        super(props);

        this.handleClick = this.handleClick.bind(this);
    
        // the user here will be shared by other pages/components throughout the app
        this.state = {
            currentSelection: 'overall',
            loading: true,
            overall: null,
            cash: null,
            point: null
        };
    }

    handleClick(e) {
        this.setState( {
            currentSelection: e.target.getAttribute('data-target')
        });
    }

    componentDidMount() {
        // only make this call if we have a valid user.
        fetch("/api/league/champions/",
        {
            cache: "no-cache",
            headers: {
                'Accept': 'application/json'
            }
        })
        .then(res => res.json())
        .then(
            (response) => {
                this.setState({
                    loading: false,
                    overall: response.wallOfChampions.overall,
                    cash: response.wallOfChampions.cash,
                    point: response.wallOfChampions.point
                });
            },
            // Note: it's important to handle errors here
            // instead of a catch() block so that we don't swallow
            // exceptions from actual bugs in components.
            (error) => {
                this.setState({
                    loading: false
                });
            }
        );
    }

    render() {
        return (
            <div className="m-wall-of-champions panel mb-3">
                <h6 className="title text-center">Wall Of Champions</h6>
                <hr />
                <div onClick={this.handleClick} className="btn-group btn-group-sm text-center mb-3" role="group" aria-label="Choose Champion Filter">
                    <button type="button" className={`btn btn-default ${(this.state.currentSelection === 'overall') ? 'active' : ''}`} data-target="overall">Overall</button>
                    <button type="button" className={`btn btn-default ${(this.state.currentSelection === 'point') ? 'active' : ''}`} data-target="point">Point</button>
                    <button type="button" className={`btn btn-default ${(this.state.currentSelection === 'cash') ? 'active' : ''}`} data-target="cash">Cash</button>
                </div>
                { this.state.loading 
                ? 
                    <div className="spinner-border text-primary" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                :
                    <div>
                        <table className={`table table-sm ${this.state.currentSelection === 'overall' ? '' : 'd-none'}`}>
                            <thead className="thead-inverse">
                                <tr>
                                    <th>Year</th>
                                    <th className="text-right">Player</th>
                                </tr>
                            </thead>
                            <ChampionList list={this.state.overall} type="overall" />
                        </table>
                        <table className={`table table-sm ${this.state.currentSelection === 'point' ? '' : 'd-none'}`}>
                            <thead className="thead-inverse">
                                <tr>
                                    <th>Year</th>
                                    <th>Player</th>
                                    <th>Points</th>
                                </tr>
                            </thead>
                            <ChampionList list={this.state.point} type="point" />
                        </table>
                        <table className={`table table-sm ${this.state.currentSelection === 'cash' ? '' : 'd-none'}`}>
                            <thead className="thead-inverse">
                                <tr>
                                    <th>Year</th>
                                    <th>Player</th>
                                    <th>Winnings</th>
                                </tr>
                            </thead>
                            <ChampionList list={this.state.cash} type="cash" />
                        </table>
                    </div>
                }
            </div>
        );
    }
}

export default WallOfChampions;