import React from 'react';
import WeekPaginator from '../components/WeekPaginator';
import GameSubmitForm from '../components/GameSubmitForm';
import { TiebreakerContext } from '../components/TiebreakerContext';

const PLAYOFF_WEEK = 90;
class SubmitPicks extends React.Component {
    constructor(props) {
        super(props);
        this.handleWeekSelected = this.handleWeekSelected.bind(this);
        this.updateSchedule = this.updateSchedule.bind(this);
        this.setTiebreaker = this.setTiebreaker.bind(this);

        this.state = {
            loading: true,
            user : null,
            schedule: null,
            site: {},
            tiebreaker: {
                value: 0,
                setTiebreaker: this.setTiebreaker
            }
        };
    }

    setTiebreaker(value) {
        const newVal = {
            ...this.state.tiebreaker,
            value,
        }
        this.setState({
            tiebreaker: newVal
        });
    }

    getPicks(week) {
        if (week >= PLAYOFF_WEEK) {
            // only make this call if we have a valid user.
            fetch('/api/playoffs/picks/', { cache: "no-cache", credentials: 'same-origin', headers: { 'Accept': 'application/json' }})
                .then(res => res.json())
                .then((response) => {
                    this.setState({
                        loading: false,
                        schedule: response.schedule,
                        user: response.user,
                        site: response.site,
                    });
                });
        } else {
            let url = '/api/user/picks/';
            if (week) {
                url += `?week=${week}`
            }

            // only make this call if we have a valid user.
            fetch(url, { cache: "no-cache", credentials: 'same-origin', headers: { 'Accept': 'application/json' }})
            .then(res => res.json())
            .then(
                (response) => {
                    if (response.status === 200) {
                        // if the server tells us that this is the playoffs, then make another call to see
                        // if this user is part of the playoffs and to get the schedule for the playoffs if available.
                        if (response.site.requestedWeek >= PLAYOFF_WEEK) {
                            this.getPicks(response.site.currentWeek);
                        } else {
                            // regular week, so just handle the response like usual.
                            const tiebreaker = {
                                ...this.state.tiebreaker,
                                value: response.site ? response.site.tiebreaker : 0,
                            }

                            this.setState({
                                loading: false,
                                schedule: response.schedule,
                                user: response.user,
                                site: response.site,
                                tiebreaker
                            });
                        }
                    } else {
                        this.setState({
                            loading: false
                        });
                    }
                },
                // Note: it's important to handle errors here
                // instead of a catch() block so that we don't swallow
                // exceptions from actual bugs in components.
                (error) => {
                    this.setState({
                        loading: false
                    });
                }
            );
        }
    }

    // allows us to update the master copy of the schedule when the user moves picks around.
    updateSchedule(schedule) {
        this.setState({
            schedule
        });
    }

    handleWeekSelected(e) {
        this.getPicks(e.target.getAttribute('data-week'));

        e.preventDefault();
    }

    componentDidMount() {
        this.getPicks();
    }

    render() {
        if (this.state.loading) {
            return <div />;
        }
        const { finalDeadlinePassed, nextDeadline, weekCount, requestedWeek, gameCount } = this.state.site;

        const showSchedule = ((requestedWeek <= weekCount) && gameCount) || (requestedWeek >= PLAYOFF_WEEK && this.state.user.playoffs);

        return (
            <div className="container mt-4">
                <div className="row">
                    <div className="col">
                        {requestedWeek >= PLAYOFF_WEEK ? 
                            <h4 className="title">
                                Playoff Picks For {this.state.user ? this.state.user.name : ''}
                                <br />
                                <span className="next-deadline font-weight-normal">Your playoff seed: {this.state.site.playoffSeed || '-'}</span>
                            </h4>

                            :
                            <h4 className="title">Week {requestedWeek} Picks for {this.state.user ? this.state.user.name : ''}
                                {!finalDeadlinePassed && 
                                    <span>
                                        <br />
                                        <span className="next-deadline font-weight-normal font-italic">(Next Deadline: {nextDeadline})</span>
                                    </span>
                                }
                            </h4>
                        }
                    </div>
                </div>
                <div className="row mb-3">
                    <div className="col-12">
                        <WeekPaginator
                        weekCount={weekCount}
                        requestedWeek={requestedWeek}
                        showPlayoffs={this.state.user && this.state.user.playoffs}
                        handleClick={this.handleWeekSelected}
                        ></WeekPaginator>
                    </div>
                    {(requestedWeek >= PLAYOFF_WEEK && this.state.user.playoffs) && 
                        <div className="col-12 mb-3">
                            <span>Order the playoff teams in the order you want them from most desirable (1) to least desirable ({gameCount}) and then hit submit to confirm your picks.</span>  Once you submit, you can <span className="font-weight-bold">NOT</span> change your picks, so please be sure
                            that the teams are ordered in the order you want them.  You only need to care about the order of the teams above the red line as you cannot receive a team that you rank lower than your playoff seed.
                        </div>
                    }
                    <div className="col-12">
                        { showSchedule ?
                            <TiebreakerContext.Provider value={this.state.tiebreaker}>
                                <GameSubmitForm
                                    site={this.state.site}
                                    user={this.state.user}
                                    schedule={this.state.schedule}
                                    updateSchedule={this.updateSchedule}
                                    isPlayoffs={requestedWeek >= PLAYOFF_WEEK}
                                ></GameSubmitForm>
                            </TiebreakerContext.Provider>
                            :
                            <div className="text-center my-5">
                                { (requestedWeek >= PLAYOFF_WEEK) ?
                                    'No playoffs for you!  Sorry.' :
                                    'The schedule for this week has not yet been posted.  Please check back later.'
                                }
                            </div>
                        }
                    </div>
                </div>
            </div>
        );
    }
}
  

export default SubmitPicks;
