import React, { useState, useEffect } from 'react';
import PlayoffSeeds from './PlayoffSeeds';
import PlayoffBracket from './PlayoffBracket';
import PlayoffBracketSuperBowl from './PlayoffBracketSuperBowl';
import './Playoffs.scss';

const SUPER_BOWL_WEEK = 93;

function Playoffs(props) {
    const [ state, setState ] = useState({
        loading: true,
        bracket: null,
        currentTab: 'bracket',
        site: {},
    });

    useEffect(() => {
        fetch("/api/playoffs/bracket/", { cache: "no-cache", credentials: 'same-origin', headers: { 'Accept': 'application/json' }})
            .then(res => res.json())
            .then(
                (response) => {
                    setState({
                        loading: false,
                        bracket: response.bracket,
                        site: response.site,
                        currentTab: 'bracket'
                    });
                },
                (error) => {
                    setState({
                        loading: false,
                        bracket: null,
                        site: {},
                        currentTab: 'bracket'
                    });
                }
            )
    }, []);

    const onSelectTab = (e) => {
        setState({
            ...state,
            currentTab: e.target.getAttribute('data-target'),
        });
    }

    return (
        <div className="m-playoffs panel mb-3">
            <h6 className="title text-center">Playoffs</h6>
            <hr />
            <div onClick={onSelectTab} className="btn-group btn-group-sm text-center mb-3" role="group" aria-label="Choose Playoff View">
                <button type="button" className={`btn btn-default ${(state.currentTab === 'seeds') ? 'active' : ''}`} data-target="seeds">Seeds</button>
                <button type="button" className={`btn btn-default ${(state.currentTab === 'bracket') ? 'active' : ''}`} data-target="bracket">Bracket</button>
            </div>

            {(state.currentTab === 'seeds') ?
                <PlayoffSeeds />
            :
                (!state.loading &&
                    <div className="playoff-bracket-container">
                        { state.site.currentWeek >= SUPER_BOWL_WEEK ? 
                            <PlayoffBracketSuperBowl schedule={state.bracket}/>
                        :
                            <>
                                <PlayoffBracket conference="nfc" schedule={state.bracket} />
                                <PlayoffBracket conference="afc" schedule={state.bracket}/>
                            </>
                        }
                    </div>
                )
            }
        </div>
    );
}

export default Playoffs;