import React, { useState } from 'react';

function MailForm(props) {
    const players = [];
    const [ recipient, setRecipient ] = useState('_RECENT_');
    const [ message, setMessage ] = useState('');
    const [ status, setStatus ] = useState();

    const handleSubmit = (e) => {
        let formData = new FormData();
        
        formData.append('recipient', recipient);
        formData.append('message', message);

        fetch("/api/admin/send-mail/",
        {
            method: 'POST',
            cache: "no-cache",
            credentials: 'same-origin', // This is required to send the cookies in the request.
            headers: {
                'Accept': 'application/json'
            },
            body: formData,
        })
        .then(res => res.json())
        .then(
            (response) => {
                setStatus(response.status);
            },
            // Note: it's important to handle errors here
            // instead of a catch() block so that we don't swallow
            // exceptions from actual bugs in components.
            (error) => {
            }
        );

        e.preventDefault();
    }

    if (props.players) {
        props.players.forEach((player, index) => {
            players.push(
                <option key={`recipient${player.playerId}`} value={player.playerId}>{player.name}</option>
            );
        });
    }

    return (
        <div>
            <div className="text-center mb-2">{status}</div>
            <form onSubmit={handleSubmit}>
                <div className="form-group">
                    <label htmlFor="recipients">Send To:</label>
                    <select value={recipient} onChange={(e) => { setRecipient(e.currentTarget.value) }} className="form-control" id="recipients">
                        <option value="_RECENT_">Recent Players</option>
                        <option value="_ACTIVE_">Active Players</option>
                        <option value="_ALL_">All Players</option>
                        {players}
                    </select>
                </div>
                <div className="form-group">
                    <label htmlFor="message">Message:</label>
                    <textarea value={message} onChange={(e) => { setMessage(e.currentTarget.value) }} className="form-control" id="message" rows="10"></textarea>
                </div>
                <div className="text-center">
                    <button type="submit" className="btn btn-primary">Send</button>
                </div>
            </form>
        </div>
    )
}

export default MailForm;